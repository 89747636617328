import React, { useState } from "react";
import { Conjunction, Visual3DWidget } from "@focus/visual3D";
import type { IConjunction as CDM } from "@focus/visual3D";
import { Button, Classes } from "@blueprintjs/core";
import { XMLViewer } from "../XMLViewer";
import { CDMInfo } from "../../services";

interface CDMTabProps {
  cdm: CDM[];
  cdmContent: CDMInfo;
}

/** Component that renders the view of a cdm out of its information  */
export const CDMViewer = ({ cdm, cdmContent }: CDMTabProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggle3DView = () => setIsVisible((prev) => !prev);

  return (
    <div style={{ width: "100%", height: "100%", padding: "10px" }}>
      <Button onClick={toggle3DView} icon={isVisible ? "eye-off" : "eye-open"}>
        {isVisible ? "Hide" : "Show"}
      </Button>
      <div className={Classes.TAB_PANEL} style={{ padding: "10px" }}>
        <XMLViewer content={cdmContent.fileContent} small={false} />
        {isVisible ? (
          <div style={{ width: "100%", height: "100%", display: "flex" }}>
            <Visual3DWidget>
              {cdm.map((e) => (
                <Conjunction cdm={e} key={e.messageId} />
              ))}
            </Visual3DWidget>
          </div>
        ) : null}
      </div>
    </div>
  );
};
