import React from "react";
import PostCamContainer from "../../containers/PostCamContainer";
import { XMLContent } from "./XMLContent";

import "../../assets/stylesheets/tabs.css";

interface CAMPanelProps {
  opmId: number;
  camPlot: any;
  availableConjHeaders: any;
}

export const CAMPanel: React.FC<CAMPanelProps> = ({
  opmId,
  camPlot,
  availableConjHeaders,
}) => {

  const getXmlContent = (opmId) => {
    if (opmId) {
      return <XMLContent opmId={opmId} />;
    }
    return undefined;
  };

  const getPostCamConjunctionsTable = (opmId) => {
    if (opmId) {
      return (
        <PostCamContainer
          opmId={opmId}
          availableConjHeaders={availableConjHeaders.value}
        />
      );
    }
    return undefined;
  };

  return (
    <div className="w-100 vertical-flex">
      {camPlot}
      {getXmlContent(opmId)}
      {getPostCamConjunctionsTable(opmId)}
    </div>
  );
};
