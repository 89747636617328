import * as React from "react";
import { GrafosJson } from "./GrafosJson";
import { GrafosBuilder } from "./GrafosBuilder";
import "../../assets/stylesheets/grafos.css";

export const groupPlots = (parents, plotsOrder, context) => {
  // 'plotTypes' stores each plot type in such a way that only one plot of each type (the most recent, placed first) is shown
  // Certain parents have multiple plots of a same type, but only the first one (most recent) needs to be displayed
  const plotTypes = {};
  if (parents === undefined) {
    return;
  }
  const grafosFiles = parents.flatMap((p) => p.plots);
  if (grafosFiles) {
    grafosFiles.forEach((gf) => {
      if (!Object.keys(plotTypes).includes(gf.plotType)) {
        plotTypes[gf.plotType] = gf.id;
      }
    });
  }

  const plotElements = {};
  const sortedPlotTypes = plotsOrder
    ? plotsOrder.filter((o) => Object.keys(plotTypes).includes(o))
    : Object.keys(plotTypes).sort();

  sortedPlotTypes.forEach((plotType) => {
    const id = plotTypes[plotType];
    switch (plotType) {
      case "SPOC":
      case "CAM":
        plotElements[plotType] = (
          <div
            className={`focusoc-plot focusoc-plot-${plotType}`}
            key={`${context}-plot-${id}`}
          >
            <GrafosJson id={id} />
          </div>
        );
        break;
      default:
        plotElements[plotType] = (
          <div
            className={`focusoc-plot focusoc-plot-${plotType}`}
            key={`${context}-plot-${id}`}
          >
            <GrafosBuilder id={id} />
          </div>
        );
    }
  });
  return plotElements;
};

const CAMPlotId = "CAM";

export const getNonCamPlots = (plotGroups) => {
  const plots = [];
  if (plotGroups) {
    Object.keys(plotGroups).forEach((k) => {
      if (k !== CAMPlotId) {
        plots.push(plotGroups[k]);
      }
    });
  }
  return plots;
};

export const getCamPlot = (plotGroups) => {
  return plotGroups?.[CAMPlotId];
};
