import { createUseFetchWithConfig } from "../helpers/hooks";
import createItemFetcher from "../helpers/item";

export interface Config {
  id: number;
}

export const grafosConfigUrlBuilder = (config: Config) => {
  const { id } = config;
  return `/api/v1/grafos/${id}`;
};

export const grafosDatUrlBuilder = (config: Config) => {
  const { id } = config;
  return `/api/v1/grafosdat/${id}`;
};

export const grafosJsonUrlBuilder = (config: Config) => {
  const { id } = config;
  return `/api/v1/grafosjson/${id}`;
};

const grafosConfigId = (config: Config) => {
  const { id } = config;
  return `grafos_config_${id}`;
};

const grafosDatId = (config: Config) => {
  const { id } = config;
  return `grafos_dat_${id}`;
};

export const grafosConfig = createItemFetcher<Config, any>(
  grafosConfigUrlBuilder,
  "GRAFOSCONFIG",
  grafosConfigId
);
export const useGrafosConfig = createUseFetchWithConfig<Config, any>(
  grafosConfig,
  (state) => state.grafosConfig
);

export const grafosDat = createItemFetcher<Config, any>(
  grafosDatUrlBuilder,
  "GRAFOSDAT",
  grafosDatId
);
export const useGrafosDat = createUseFetchWithConfig<Config, any>(
  grafosDat,
  (state) => state.grafosDat
);

export const grafosJson = createItemFetcher<Config, any>(
  grafosJsonUrlBuilder,
  "GRAFOSJSON",
  grafosDatId
);
export const useGrafosJson = createUseFetchWithConfig<Config, any>(
  grafosJson,
  (state) => state.grafosJson
);
