import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import "normalize.css/normalize.css";

import './App.css';
import "../assets/stylesheets/internal_pages.css";
import "../assets/stylesheets/table.css";
import "../assets/stylesheets/header.css";
import "../assets/stylesheets/grafos.css";

import { Header } from './Header';
import { SideBar } from './SideBar';
import { Content } from "./Content";
import Layout from './Layout';

interface AppProps {
  store: any;
}

const App: React.FC<AppProps> = ({ store }) => {
  return (
      <Provider store={store}>
        <HashRouter>
          <Layout
            store={store}
            logo={""}
            Header={Header}
            Menu={SideBar}
            Content={Content}
          />
        </HashRouter>
      </Provider>
  );
}
export default App;