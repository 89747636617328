import { AppDescription } from "./AppsMenu";

export const focusOcApps: AppDescription[] = [
  {
    id: "fleet",
    link: "/fleet",
    icon: "heatmap",
    text: "Status",
  },
  {
    id: "conjunctions",
    link: "/conjunctions",
    icon: "intersection",
    text: "Conjunctions",
  },
  {
    id: "orbits",
    link: "/orbits",
    icon: "social-media",
    text: "Orbits",
  },
  {
    id: "upload",
    link: "/upload",
    icon: "upload",
    text: "Upload",
  },
];
