import React from "react";
import { applyFormat } from "../../helpers/utils";
import { Label } from "../Label";
import { ConjunctionElement } from "./ConjunctionElement";

interface ConjunctionInformationDetailsProps {
  conjunction: any;
  formatters: any;
}

export const ConjunctionInformationDetails: React.FC<
  ConjunctionInformationDetailsProps
> = ({ conjunction, formatters }) => {
  return (
    <div className="foc-tab-content left-aligned">
      <div className="foc-detail-main-content">
        <div className="datainside" style={{ paddingBottom: "35px" }}>
          <div className="datainside-item">
            <Label
              title={"Operator: "}
              value={conjunction?.operator?.value?.toUpperCase()}
            />
            <Label title={"Event ID: "} value={conjunction?.event?.eventId} />
            <Label
              title={"Conjunction Identifier: "}
              value={conjunction?.conjunctionIdentifier}
            />
            <Label title={"TCA: "} value={conjunction?.timeOfCloseApproach} />
            <Label
              title={"Detection time: "}
              value={conjunction?.detectionTime}
            />
            <Label
              title={"Start time: "}
              value={conjunction?.analysisTimeStart}
            />
            <Label title={"End time: "} value={conjunction?.analysisTimeEnd} />
            <Label
              title={"Execution time: "}
              value={conjunction?.executionEpoch}
            />
            <Label
              title={"Collision Probability: "}
              value={applyFormat(
                conjunction,
                "collisionProbability.value",
                formatters
              )}
            />
            <Label
              title={"Collision Probability Threshold: "}
              value={applyFormat(
                conjunction,
                "collisionProbabilityThreshold.value",
                formatters
              )}
            />
          </div>
          <div className="datainside-right">
            <Label
              title={"Risk Level: "}
              value={conjunction?.riskLevel}
            />
            <Label
              title={"Miss Distance: "}
              value={applyFormat(conjunction, "missDistance.value", formatters)}
            />
            <Label
              title={"Miss Distance Threshold: "}
              value={applyFormat(
                conjunction,
                "missDistanceThreshold.value",
                formatters
              )}
            />
            <Label
              title={"Radial Distance: "}
              value={applyFormat(
                conjunction,
                "radialDistance.value",
                formatters
              )}
            />
            <Label
              title={"Cross Track Distance: "}
              value={applyFormat(
                conjunction,
                "crossTrackDistance.value",
                formatters
              )}
            />
            <Label
              title={"Screening Volume Along Track: "}
              value={applyFormat(
                conjunction,
                "screeningVolumeAlongTrack.value",
                formatters
              )}
            />
            <Label
              title={"Screening Volume Cross Track: "}
              value={applyFormat(
                conjunction,
                "screeningVolumeCrossTrack.value",
                formatters
              )}
            />
            <Label
              title={"Screening Volume Radial: "}
              value={applyFormat(
                conjunction,
                "screeningVolumeRadial.value",
                formatters
              )}
            />
            <Label
              title={"Maximum Collision Probability: "}
              value={applyFormat(
                conjunction,
                "maximumCollisionProbability.value",
                formatters
              )}
            />
            <Label
              title={"Maximum Collision Probability Threshold: "}
              value={applyFormat(
                conjunction,
                "maximumCollisionProbabilityThreshold.value",
                formatters
              )}
            />
          </div>
        </div>
      </div>
      <div className="datainside">
        <ConjunctionElement conjunction={conjunction} orbitElement={"target"} formatters={formatters} />
        <ConjunctionElement conjunction={conjunction} orbitElement={"chaser"} formatters={formatters} />
      </div>
    </div>
  );
};
