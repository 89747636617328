import { connect } from "react-redux";
import { PostCAMConjunctionsTable } from "../components/postcam/PostCAMConjunctionsTable";
import { postOpmConjunctionsColumnsLayout } from "../services/configuration";
import { opmConjunctions } from "../services/postcam";

const postOpmConjunctionsActions = opmConjunctions.actions;
const postOpmConjunctionsSelectors = opmConjunctions.selectors;
const postOpmConjunctionsLayoutActions = postOpmConjunctionsColumnsLayout.actions;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  getStatePostOpmConjunctionsFilter: () => {
    return postOpmConjunctionsSelectors.getFilter(state.opmConjunctions);
  },
});

const mapDispatchToProps = (dispatch) => ({
  setStatePostOpmConjunctionsFilter: (filter) => {
    return dispatch(postOpmConjunctionsActions.setFilter(filter));
  },
  savePostOpmConjunctionColumns: (columns) => {
    return dispatch(postOpmConjunctionsLayoutActions.saveItems(columns));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostCAMConjunctionsTable);