const toUpperCaseFormatter = (params) => {
  return params.value?.toUpperCase();
};

export const OPERATORCOLUMN = {
  headerName: "Operator",
  field: "operator.value",
  cellClass: "lock-pinned",
  filter: "agTextColumnFilter",
  filterParams: {
    buttons: ["apply", "reset"],
    closeOnApply: true,
  },
  valueFormatter: toUpperCaseFormatter,
  minWidth: 100
};
