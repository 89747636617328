import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "../../assets/stylesheets/internal_pages.css";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../../assets/stylesheets/ag-grid_styles.css";
import "../../assets/stylesheets/tabs.css";

import { OrbitsDatabaseTab, SATORBIT_TAB } from "./OrbitsDatabaseTab";

interface OrbitsDatabaseProps {
  match: {
    params: {
      page: number;
    };
  };
  history: any;
  location: any;
  downloadOrbits: (param: { id: string }) => void;
  orbitsFilter: object;
  saveOrbitColumns: (param: object) => void;
  saveOrbitsFilter: (param: object) => void;
}

export const OrbitsDatabase: React.FC<OrbitsDatabaseProps> = ({
  match,
  history,
  location,
  downloadOrbits,
  orbitsFilter,
  saveOrbitColumns,
  saveOrbitsFilter,
}) => {
  return (
    <div className="foc-content">
      <div className="foc-page_title">
        <span>Orbit files</span>
      </div>
      <Tabs animate={true} id="orbit-tabs" className="foc-table-container">
        <Tab
          id="operational"
          title="Operational"
          panel={
            <OrbitsDatabaseTab
              history={history}
              location={location}
              page={match.params.page}
              source={SATORBIT_TAB.OPS}
              downloadOrbits={downloadOrbits}
              orbitsFilter={orbitsFilter}
              saveOrbitColumns={saveOrbitColumns}
              saveOrbitsFilter={saveOrbitsFilter}
            />
          }
        />
      </Tabs>
    </div>
  );
};
