import React from "react";

interface LabelProps {
  title: string;
  value: any;
}

export const Label: React.FC<LabelProps> = ({ title, value }) => {
  const isNotNullNorUndefined = (variable) => {
    return typeof variable !== "undefined" && variable !== null;
  };

  const parseValueAndUnits = (value) => {
    let displayedText = "";
    if (typeof value === "object" && value !== null) {
      if (isNotNullNorUndefined(value.value)) {
        displayedText = `${value.value}`;
        if (isNotNullNorUndefined(value.units)) {
          displayedText = `${value.value} ${value.units}`;
        }
      }
    } else {
      displayedText = value;
    }
    return displayedText;
  };

  return (
    <div className="foc-detail-list-row">
      <span className="foc-detail-list-label">{title}</span>
      <span className="foc-detail-list-value">{parseValueAndUnits(value)}</span>
    </div>
  );
};
