import { Observable } from "rxjs";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { Duck } from "@focus/interfaces/lib/common/Duck";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";

export default function createServerConfiguration(
): Duck {
  const url = `/api/v1/serverconfig`;

  // define constants
  const SET = `SERVER_CONFIG/SET`;
  const FETCH = `SERVER_CONFIG/FETCH_START`;
  const FETCH_ERROR = `SERVER_CONFIG/FETCH_ERROR`;
  const FETCH_PENDING = `SERVER_CONFIG/FETCH_PENDING`;

  // ACTIONS
  function fetchItems() {
    return { type: FETCH };
  }
  function setItems(payload) {
    return { type: SET, payload };
  }
  function setItemsPending() {
    return { type: FETCH_PENDING };
  }
  function setError(payload: string) {
    return { type: FETCH_ERROR, payload };
  }

  // REDUCER
  // default state
  const initialState = {
    config: {
      status: "",
      errorMessage: "",
      content: {
        applications: [],
        configurations: [
          {name: "fleet", available: true},
          {name: "conjunctions", available: true},
          {name: "orbits", available: true},
          {name: "upload", available: true},
        ]
      }
    }
  };

  /**
   * Reducer to handle the configuration
   *
   * @param  {Object} state={}
   * @param  {Object} action
   */
  function reducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PENDING:
        return Object.assign({}, state, {
          items: [],
          status: ItemStatus.PENDING,
          errorMessage: "",
        });
      case FETCH_ERROR:
        return Object.assign({}, state, {
          items: [],
          status: ItemStatus.ERROR,
          errorMessage: action.payload,
        });
      case SET:
        return Object.assign({}, state, {
          items: action.payload,
          status: ItemStatus.READY,
          errorMessage: "",
          save: undefined,
        });
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * @param  {Object} state
   */
  function getItems(state) {
    return state.items;
  }

  /**
   * @param  {Object} state
   */
  function getStatus(state) {
    return state.status;
  }
  /**
   * Get the error message if any
   * @param  {} state the state object
   */
  function getError(state) {
    return state.errorMessage;
  }

  /**
   * Epic creator for the fetch
   *
   * @param  {} action$ Actions stream
   * @param  {} _
   * @param  {} {ajax} The ajax service
   */
  const fetchItemsEpic = (action$, _, { ajax }) =>
    action$.ofType(FETCH).mergeMap((action) =>
      ajax
        .getJSON(url)
        .map((response) => setItems(response))
        .catch((error: FocusAjaxError, source) => {
          return Observable.of(setError(getErrorMessage(error)));
        })
        .startWith(setItemsPending())
    );

  const actionTypes = {
    SET,
    FETCH,
    FETCH_ERROR,
    FETCH_PENDING,
  };

  const actions = {
    fetchItems,
    setItems,
    setError,
  };

  const selectors = {
    getItems,
    getStatus,
    getError,
  };

  const middleware = [fetchItemsEpic];
  return { actions, reducer, middleware, selectors, actionTypes };
}
