import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/observable/dom/AjaxObservable";
import KeycloakManager from "../keycloak";
import {
  setDownloadFileError,
  setDownloadFileFinish,
  setDownloadFilePending,
  setDownloadFileZipError,
  setDownloadFileZipFinish,
  setDownloadFileZipPending,
  setUploadFileError,
  setUploadFileFinish,
  setUploadFilePending,
} from "./actions";
import { actionTypes } from "./actionTypes";

import { Intent } from "@blueprintjs/core";
import { addNotification } from "@focus/services/lib/toaster/actions";

const REG_CONTENT_DISP = /filename="(.*)"/;

function getFileName(contentDisposition: string) {
  return REG_CONTENT_DISP.exec(contentDisposition)[1] || "test.zip";
}

const saveFile = (saveAs) => (filename: string, response: AjaxResponse) => {
  const receivedFileName = getFileName(filename);
  saveAs(response.response, receivedFileName);
};

export const uploadOrbit =
  (uploadOrbitUrl) =>
  (action$, _, { ajax }) => {
    return action$.ofType(actionTypes.UPLOAD).mergeMap((action) => {
      const fd = new FormData();
      action.payload?.files?.forEach((fileObject) => {
        fd.append("orbit-file", fileObject.file);
      });
      return ajax({
        url: uploadOrbitUrl(action.payload.operator),
        headers: { Authorization: `Bearer ${KeycloakManager.getToken()}` },
        method: "POST",
        body: fd,
      })
        .map(() => setUploadFileFinish(action.payload))
        .catch((error: FocusAjaxError) =>
          Observable.of(
            setUploadFileError(action.payload, getErrorMessage(error))
          )
        )
        .startWith(setUploadFilePending(action.payload));
    });
  };

export const showUploadErrorPopup =
  () =>
  (action$, _, { ajax }) => {
    return action$.ofType(actionTypes.UPLOAD_ERROR).mergeMap((action) => {
      return Observable.of(
        addNotification({
          intent: Intent.DANGER,
          message: `An Error occured while uploading: ${action.error}`,
        })
      );
    });
  };
export const showUploadOkPopup =
  () =>
  (action$, _, { ajax }) => {
    return action$.ofType(actionTypes.UPLOAD_FINISH).mergeMap((action) => {
      return Observable.of(
        addNotification({
          intent: Intent.SUCCESS,
          message: `Files uploaded successfully`,
        })
      );
    });
  };

export const downloadZipOrbit =
  (downloadOrbitUrl, saveAs) =>
  (action$, _, { ajax }) => {
    return action$.ofType(actionTypes.DOWNLOAD_ZIP).mergeMap((action) => {
      return ajax({
        url: downloadOrbitUrl(action.payload),
        headers: {
          "content-type": "application/zip",
          Authorization: `Bearer ${KeycloakManager.getToken()}`,
        },
        responseType: "blob",
      })
        .do((res) =>
          saveFile(saveAs)(
            res.xhr.getResponseHeader("content-disposition"),
            res
          )
        )
        .map(() => setDownloadFileZipFinish(action.payload))
        .catch((error: FocusAjaxError) =>
          Observable.of(
            setDownloadFileZipError(action.payload, getErrorMessage(error))
          )
        )
        .startWith(setDownloadFileZipPending(action.payload));
    });
  };

export const downloadOrbit =
  (downloadOrbitUrl, saveAs) =>
  (action$, _, { ajax }) => {
    return action$.ofType(actionTypes.DOWNLOAD).mergeMap((action) => {
      return ajax({
        url: downloadOrbitUrl(action.payload),
        headers: {
          "content-type": "application/octet-stream",
          Authorization: `Bearer ${KeycloakManager.getToken()}`,
        },
        responseType: "blob",
      })
        .do((res) =>
          saveFile(saveAs)(
            res.xhr.getResponseHeader("content-disposition"),
            res
          )
        )
        .map(() => setDownloadFileFinish(action.payload))
        .catch((error: FocusAjaxError) =>
          Observable.of(
            setDownloadFileError(action.payload, getErrorMessage(error))
          )
        )
        .startWith(setDownloadFilePending(action.payload));
    });
  };
