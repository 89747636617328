import { createUseFetchWithConfig } from "../helpers/hooks";
import createItemFetcher from "../helpers/item";

export interface PlotConfig {
  id: string;
  context: string;
}

export const plotUrl = (config: PlotConfig) => {
  const { id, context } = config;
  return `/api/v1/plot?${context}=${id}`;
};

const plotId = (config: PlotConfig) => {
  const { id, context } = config;
  return `plots_${context}_${id}`;
};

const plotsFetcher = () =>
  createItemFetcher<PlotConfig, any>(plotUrl, "PLOTS", plotId);
export const plots = plotsFetcher();
export const usePlots = createUseFetchWithConfig<PlotConfig, any>(
  plots,
  (state) => state.plots
);
