import type { IConjunction as CDM } from "@focus/visual3D";
import { combineDucks } from "../helpers/combineDucks";
import { createUseFetchWithConfig } from "../helpers/hooks";
import createItemFetcher from "../helpers/item";

export type NDMConfig = string;

export interface CDMInfo {
  id: number;
  operator: string;
  filename: string;
  fileContent: string;
}

const cdmId = ( id: NDMConfig) => {
  return `cdm_${id}`;
};


const cdmInfoUrlBuilder = (id: NDMConfig) => {
  return `/api/v1/cdms/${id}`;
};

export const cdmInfo = createItemFetcher<NDMConfig, unknown>(
  cdmInfoUrlBuilder,
  "CDM",
  cdmId
);


function cdmDataUrlBuilder(id: NDMConfig): string {
  return `api/v1/cdms/${id}/data`;
}

export const cdmData = createItemFetcher<NDMConfig, unknown>(
  cdmDataUrlBuilder,
  "CDM_GET",
  cdmId
);


/** Hooks and combined duck */

export const useCdmInfo = createUseFetchWithConfig<NDMConfig, CDMInfo>(
  cdmInfo,
  (state) => state.cdm.info
);

export const useCdmData = createUseFetchWithConfig<NDMConfig, CDM[]>(
  cdmData,
  (state) => state.cdm.data
);


export const cdmDuck = combineDucks({
  data: cdmData,
  info: cdmInfo
})