import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import * as React from "react";
import "../../assets/stylesheets/ag-grid_styles.css";

import { CONJ_DEFAULT_VISIBLE_COLUMNS } from "../../services/conjunctions/Headers";

import {
  useAvailableconjunctionHeaders,
  useSavedFleetConjunctionColumns
} from "../../services/configuration";

import { StatusProxy } from "@focus/focusUI";
import { useFleetConjunctions } from "../../services/conjunctions";
import {
  getColumnDefinitions,
  getVisibleColumns
} from "../../services/helpers/headers";
import { OperatorFleetTable } from "./OperatorFleetTable";

interface FleetTableProps {
  downloadZipOrbits: (param: { id: string }) => void;
  saveFleetConjunctionColumns: () => void;
  setStateFleetConjunctionsFilter: (param: { filter: object }) => void;
  getStateFleetConjunctionsFilter: () => { filter: object };
  setNumberOfConjunctionsInTitle: (param: number) => void;
}

export const FleetTable: React.FC<FleetTableProps> = ({
  downloadZipOrbits,
  saveFleetConjunctionColumns,
  setStateFleetConjunctionsFilter,
  getStateFleetConjunctionsFilter,
  setNumberOfConjunctionsInTitle,
}) => {
  // Request the user active columns to the server's DB
  const headersConfig = useAvailableconjunctionHeaders();
  const columnsConfig = useSavedFleetConjunctionColumns();
  const columnDefinitions = getColumnDefinitions(headersConfig.value, true);
  // If no headers are available in the DB, load the default active columns
  const visibleColumns = getVisibleColumns(
    columnsConfig.items,
    CONJ_DEFAULT_VISIBLE_COLUMNS,
    columnDefinitions
  );

  const conjunctions = useFleetConjunctions();
  return (
    <StatusProxy status={headersConfig.status} error={headersConfig.error}>
      <StatusProxy status={columnsConfig.status} error={columnsConfig.error}>
        <StatusProxy status={conjunctions.status} error={conjunctions.error}>
          <OperatorFleetTable
            downloadZipOrbits={downloadZipOrbits}
            saveFleetConjunctionColumns={saveFleetConjunctionColumns}
            setStateFleetConjunctionsFilter={setStateFleetConjunctionsFilter}
            getStateFleetConjunctionsFilter={getStateFleetConjunctionsFilter}
            setNumberOfConjunctionsInTitle={setNumberOfConjunctionsInTitle}
            visibleColumns={visibleColumns}
            columnDefinitions={columnDefinitions}
            conjunctions={conjunctions.items}
          />
        </StatusProxy>
      </StatusProxy>
    </StatusProxy>
  );
};
