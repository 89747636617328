import React from "react";
import "../../assets/stylesheets/internal_pages.css";
import "../../assets/stylesheets/upload.css";

import { Icon, ProgressBar, Intent, Button } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { CustomLabel, SelectMenu } from "@focus/focusUI";
import KeycloakManager from "../../services/keycloak";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadFile,
  setUploadFileDelete,
} from "../../services/downloaduploadOrbits/actions";

interface UploadPanelProps {}

/**
 * UploadPanel is a functional component that renders the panel used to upload orbit files
 */
export const UploadPanel: React.FC<UploadPanelProps> = ({}) => {
  const uploadedFilesStore = useSelector((state: any) => state.uploadedOrbits);

  const dispatch = useDispatch();
  const uploadOrbits = (payload) => dispatch(uploadFile(payload));
  const deleteOrbits = () => dispatch(setUploadFileDelete());

  // Initialize staged and uploaded files
  const [filesStaged, setFilesStaged] = React.useState([]);
  const [filesUploaded, setFilesUploaded] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  // Clean orbits when first mounting the component
  React.useEffect(() => {
    deleteOrbits();
  }, []);

  const isFileAlreadyStaged = (staged, file) => {
    let wasStaged = false;
    staged?.forEach((element) => {
      if (element.file.name === file.name) {
        wasStaged = true;
      }
    });
    return wasStaged;
  };

  const getFilesToBeStaged = (filesToBeUploaded) => {
    const filesArray = filesStaged.slice();
    for (let pos = 0; pos < filesToBeUploaded.length; pos += 1) {
      if (!isFileAlreadyStaged(filesArray, filesToBeUploaded[pos])) {
        filesArray.unshift({ id: counter + pos, file: filesToBeUploaded[pos] });
      }
    }
    setCounter(counter + filesToBeUploaded.length);
    return filesArray;
  };

  /**
   * preventEvent is used to ommit the normally occurring effects caused by an event. In
   * this case, it allows the drag events to be used correctly.
   * @param e An event
   */
  const preventEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * handleDrop substitutes the usually occurring drop event by an staging of the dropped
   * file, as an alternative to the file input.
   * @param e The drop event
   */
  const handleDrop = (e) => {
    preventEvent(e);
    const files = getFilesToBeStaged(e.dataTransfer.files);
    setFilesStaged(files);
  };

  /**
   * handleAdd adds a new file to the current stagedFiles
   * @param e Change event
   */
  const handleAdd = (e) => {
    const files = getFilesToBeStaged(e.target.files);
    setFilesStaged(files);
  };

  const operators = KeycloakManager.getTokenOperators();
  const [origin, setOrigin] = React.useState(operators[0]);

  /**
   * uploadStagedOrbits uses the staged files and calls the prop function, resetting the state
   * as a result
   */
  const uploadStagedOrbits = () => {
    uploadOrbits({ files: filesStaged, operator: origin });
    const newFiles = filesStaged.concat(filesUploaded);
    setFilesUploaded(newFiles);
    setFilesStaged([]);
  };

  /**
   * getProgressIntent handles the correct highlighting of the files as they are staged and uploaded
   * @param fileObject The file object
   */
  const getProgressIntent = (fileObject) => {
    const fileId = fileObject.id;
    let returnValue: Intent = Intent.NONE;

    switch (uploadedFilesStore[fileId].status) {
      case "ERROR":
        returnValue = Intent.DANGER;
        break;
      case "READY":
        returnValue = Intent.SUCCESS;
        break;
      case "PENDING":
        returnValue = Intent.PRIMARY;
        break;
      default:
        returnValue = Intent.NONE;
        break;
    }
    return returnValue;
  };

  /**
   * getUploadOperator returns the operator used to upload the given file
   * @param fileObject The file object
   */
  const getUploadOperator = (fileObject) => {
    const fileId = fileObject.id;
    return uploadedFilesStore[fileId].operator;
  };

  return (
    <div className="foc-content">
      <div className="foc-page_title">
        <span>Upload orbit files</span>
      </div>
      <CustomLabel text="Operator" className="w-100">
        <SelectMenu onChange={setOrigin} value={origin} options={operators} />
      </CustomLabel>

      <div
        className="foc-upload-container"
        onDrop={handleDrop}
        onDragOver={preventEvent}
        onDragEnter={preventEvent}
        onDragLeave={preventEvent}
      >
        <label className="foc-upload_label">
          <input
            className="foc-upload_input"
            type="file"
            onChange={handleAdd}
            onClick={(event) => {
              event.currentTarget.value = null;
            }}
          />
          <Icon icon="cloud-upload" title="Upload" />
          &nbsp;
          <b>Add file</b> or drop file here
        </label>
      </div>

      <div className="foc-upload-list-container">
        {filesStaged.map((element, index) => (
          <div className="foc-upload-list-row" key={index}>
            <span className="foc-upload-list-label">{element.file?.name}</span>
            <span className="foc-upload-list-progress">
              {element.file?.size / 1000}kb
            </span>
            <ProgressBar value={0} stripes={false} intent={Intent.NONE} />
          </div>
        ))}
        {filesUploaded.map((element, index) => (
          <div className="foc-upload-list-row" key={index}>
            <span className="foc-upload-list-label">{`Operator: ${getUploadOperator(
              element
            )}`}</span>
            <span className="foc-upload-list-label">{element.file?.name}</span>
            <span className="foc-upload-list-progress">
              {element.file?.size / 1000}kb
            </span>
            <ProgressBar
              value={1}
              stripes={false}
              intent={getProgressIntent(element)}
            />
          </div>
        ))}
        <Button icon="circle-arrow-up" onClick={uploadStagedOrbits}>
          Upload
        </Button>
      </div>
    </div>
  );
};
