import { ajax } from "rxjs/observable/dom/ajax";
import { ActionKeys } from "@focus/services/lib/user/actionTypes";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { Observable, Observer } from "rxjs";
import KeycloakManager from "../services/keycloak";

let addLogoutAction = (action?: Object) => {
  console.log(action);
};

/**
 * Epic to handle the ajax 401 errors and reflect the logged out state
 */
export const authErrorEpic = () =>
  Observable.create((observer: Observer<any>) => {
    addLogoutAction = action => {
      observer.next(action);
    };
    // create fake unsubcription
    return () => {};
  });

// filter errors of authentication
const interceptAuthError = (error: FocusAjaxError) => {
  if (error.status === 401) {
    addLogoutAction({ type: ActionKeys.UNSET_USER });
  }
  return Observable.throw(error);
};

// wrap ajax so it handles authentication errors
const _ajax = () => {
  function ajaxHandler(args) {
    return ajax(args).catch(interceptAuthError);
  }

  ajaxHandler.getJSON = (url: string) => {
    const head = {Authorization : `Bearer ${KeycloakManager.getToken()}`};
    return ajax.getJSON(url, head).catch(interceptAuthError);
  }
  return ajaxHandler;
};

export default _ajax;