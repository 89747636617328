import React from "react";
import {
  Route,
  Redirect,
  withRouter,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import ConjunctionsContainer from "../containers/ConjunctionsContainer";
import FleetContainer from "../containers/FleetContainer";
import OrbitsContainer from "../containers/OrbitsContainer";

import { ConjunctionInformation } from "./conjunctions/ConjunctionInformation";
import { OrbitInformation } from "./orbits/OrbitInformation";
import { UploadPanel } from "./upload/UploadPanel";

// TODO: UPDATE THIS PANEL AND ADD FUNCTIONALITY
// import { ConfigurationPanel } from "./configuration/ConfigurationPanel";

const _Content: React.FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route path="/conjunctions/:id/info">
        <ConjunctionInformation />
      </Route>
      <Route
        path="/orbits/:id/info"
        render={(props) => <OrbitInformation {...props} />}
      />
      <Route path="/fleet/:id/info">
        <ConjunctionInformation pageTitle={"Fleet status"} />
      </Route>
      <Route
        path="/conjunctions/:page?"
        render={(props) => <ConjunctionsContainer {...props} />}
      />
      <Route
        path="/orbits/:page?"
        render={(props) => <OrbitsContainer {...props} />}
      />
      <Route exact path="/upload" render={() => <UploadPanel />} />
      <Route path="/fleet" render={(props) => <FleetContainer {...props} />} />
      <Route exact path="/" render={() => <Redirect to="/fleet" />} />
      {/* <Route exact path='/settings' render={() => <ConfigurationPanel />} /> */}
    </Switch>
  );
};

export const Content = withRouter(_Content);
