export const COLUMNDEFS_OPS = [
  {
    headerName: "Cospar Id",
    hide: true,
    field: "spaceObject.cosparId.value",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Norad Id",
    hide: true,
    field: "spaceObject.noradId.value",
    filter: "agNumberColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Name",
    hide: true,
    field: "spaceObject.name.value",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Start Date",
    hide: true,
    field: "startDate.value",
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "End Date",
    hide: true,
    field: "endDate.value",
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Creation date",
    hide: true,
    field: "creationDate.value",
    filter: "agDateColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Filename",
    hide: true,
    field: "orbitFile.value",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Category",
    hide: true,
    field: "category.value",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
];

export const COLUMNDEFS_CAT = [
  {
    headerName: "Id",
    hide: true,
    field: "id",
    filter: "agNumberColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Cospar Id",
    hide: true,
    field: "spaceObject.cosparId.value",
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  },
];
