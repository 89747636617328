import React from "react";
import "../assets/stylesheets/header.css";
import logo_gmv from "../assets/images/logo_gmv.svg";
import logo_sat from "../assets/images/logo.svg";

import KeycloakManager from "../services/keycloak";
import { Hamburger } from "@focus/focusUI";
import { MenuItem, Popover, Menu, Button, Position } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";

interface HeaderProps {
  collapsed: boolean;
  onChange: () => void;
}

export const Header: React.FC<HeaderProps> = ({ collapsed, onChange }) => {
  return (
    <div className="foc-header">
      <nav className="grey bp4-dark">
        <div className="bp4-navbar-group bp4-align-left">
          <Hamburger collapsed={collapsed} onChange={onChange} />
          <img className="foc-header_logo_gmv" src={logo_gmv} alt="Logo GMV" />
          <div className="title">Focusoc</div>
          <img className="foc-header_logo_focusoc" src={logo_sat} alt="Logo SAT" />
        </div>

        <div className="bp4-navbar-group bp4-align-right">
          <Popover
            className="bp4-dark"
            content={
              <Menu>
                <MenuItem
                  icon="log-out"
                  onClick={() => KeycloakManager.doLogout()}
                  text="Logout"
                />
              </Menu>
            }
            position={Position.BOTTOM_LEFT}
          >
            <Button
              className="bp4-minimal"
              icon="user"
              text={KeycloakManager.getUsername()}
            />
          </Popover>
        </div>
      </nav>
    </div>
  );
};
