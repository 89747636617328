import { Observable } from "rxjs";
import { Duck } from "@focus/interfaces/lib/common/Duck";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";

export interface ConfigDetail {
  id: string;
  urlContext: string;
}

export function createDetailFetcher(element: string): Duck {
  function computeURL(config: ConfigDetail) {
    const { id, urlContext } = config;
    return `/api/v1/${urlContext}/${id}`;
  }

  // define constants
  const SET = `${element.toUpperCase()}/SET`;
  const FETCH = `${element.toUpperCase()}/FETCH_START`;
  const FETCH_STOP = `${element.toUpperCase()}/FETCH_STOP`;
  const FETCH_ERROR = `${element.toUpperCase()}/FETCH_ERROR`;
  const FETCH_PENDING = `${element.toUpperCase()}/FETCH_PENDING`;

  // ACTIONS
  const fetch = (payload) => ({
    type: FETCH,
    payload,
  });
  const stopFetching = (payload) => ({
    type: FETCH_STOP,
    payload,
  });
  const setFetchError = (id, payload) => ({
    type: FETCH_ERROR,
    id,
    payload,
  });
  const fetchPending = (id) => ({
    type: FETCH_PENDING,
    id,
  });
  const set = (id, payload) => ({
    type: SET,
    id,
    payload,
  });

  // REDUCER
  /**
   * Default state
   */
  const initialState = {
    content: undefined,
    status: ItemStatus.PENDING,
    errorMessage: "",
  };
  /**
   * Reducer to handle the individual items
   *
   * @param  {Object} state={}
   * @param  {Object} action
   */
  function reducer(state = initialState, action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          content: action.payload,
          status: ItemStatus.READY,
          errorMessage: "",
        };
      case FETCH_ERROR:
        return {
          ...state,
          content: undefined,
          status: ItemStatus.ERROR,
          errorMessage: action.payload,
        };
      case FETCH_PENDING:
        return {
          ...state,
          content: undefined,
          status: ItemStatus.PENDING,
          errorMessage: "",
        };
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * @param  {Object} state the state object
   * @param  {String} id the id of the conjunction to retrieve
   */
  function getContent(state) {
    return state.content;
  }
  /**
   * @param  {Object} state
   */
  function getStatus(state) {
    return state.status;
  }
  /**
   * Get the error message if any
   * @param  {} state the state object
   */
  function getError(state) {
    return state.errorMessage;
  }

  // MIDDLEWARE
  /**
   * Epic creator for the fetch
   *
   * @param  {} action$ Actions stream
   * @param  {} _ the store , not used
   * @param  {} {ajax} The ajax service
   */
  const fetchEpic = (action$, _, { ajax }) =>
    action$.ofType(FETCH).mergeMap((action) =>
      ajax
        .getJSON(computeURL(action.payload))
        .map((response) => set(action.payload.id, response))
        .catch((error: FocusAjaxError) =>
          Observable.of(
            setFetchError(action.payload.id, getErrorMessage(error))
          )
        )
        .takeUntil(
          action$
            .filter(
              (stopAction) =>
                stopAction.payload === action.payload &&
                stopAction.type === FETCH_STOP
            )
            .take(1)
        )
        .startWith(fetchPending(action.payload.id))
    );

  const actionTypes = {
    SET,
    FETCH,
    FETCH_STOP,
    FETCH_ERROR,
    FETCH_PENDING,
  };
  const actions = {
    fetch,
    stopFetching,
    setFetchError,
    set,
    fetchPending,
  };
  const selectors = {
    getContent,
    getStatus,
    getError,
  };
  const middleware = [fetchEpic];
  return {
    actions,
    reducer,
    middleware,
    selectors,
    actionTypes,
  };
}