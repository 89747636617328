import React from "react";

import { AgGridReact } from "ag-grid-react";
import { ColDef, GridOptions, RowClassRules } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../../assets/stylesheets/ag-grid_styles.css";

import { OPERATORCOLUMN } from "../../helpers/tableColumnFormatter";
import { TableActionColumn } from "./TableActionColumn";
import { TableSideBar } from "./TableSideBar";
import { actionsColumnName } from "../../services/helpers/headers";
import { isEqual } from "lodash";
import { TABLE_ACTION } from "./configurations";

interface TableProps {
  saveColumns: any;
  columnDefinitions: ColDef[];
  activeColumns: { colId: string; hide: boolean }[];
  adaptToContainerHeight?: boolean;
  gridOptions: GridOptions;
  rowData?: any[];
  rowClassRules?: RowClassRules;
  context: {
    source: string;
    base: string;
    actions: TABLE_ACTION[];
    downloadData: (param: { id: string }) => void;
  };
  columnApi;
}

export const Table: React.FC<TableProps> = ({
  saveColumns,
  columnDefinitions,
  activeColumns,
  adaptToContainerHeight = true,
  gridOptions,
  rowData,
  rowClassRules,
  context,
  columnApi,
}) => {
  const columns = columnDefinitions.map((c) => {
    return c;
  });

  // Include 'operator' column in the table
  columns.push(OPERATORCOLUMN);

  const saveColumnsConfiguration = (
    params,
    oldColumns,
    newColumns,
    columnsSaver
  ) => {
    // Save columns only if the visible-columns/columns-order has changed
    if (!isEqual(oldColumns, newColumns)) {
      columnsSaver(params.columnApi.getColumnState());
    }
  };

  const getColumnsOrder = (params) => {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const displayedColumnNames = displayedColumns.map((col) => {
      return col.colId;
    });

    const lastShownColumn = displayedColumns[displayedColumns.length - 1];
    lastShownColumn.flex = 1;
    return displayedColumnNames;
  };

  /**
   * toggleVisibility switches between making a column visible or invisible
   * @param name The name of the column to toggle
   */
  const toggleVisibility = (name: string) => {
    setCheckboxes({
      ...checkboxes,
      [name]: !checkboxes[name],
    });
    columnApi.setColumnVisible(name, checkboxes[name]);
  };

  const loadActiveHeaders = (columns, activeColumns) => {
    const baseCheckboxes = columns.reduce(
      (checks, col) => ({
        ...checks,
        [col.field]: true,
      }),
      {}
    );

    return activeColumns.reduce(
      (checks, name) => ({
        ...checks,
        [name.colId]: name.hide,
      }),
      baseCheckboxes
    );
  };

  // The state of the checkboxes that represent the column visibilities
  const [checkboxes, setCheckboxes] = React.useState(
    loadActiveHeaders(columns, activeColumns)
  );

  const getVisibleColumnsBasedOnCheckboxes = (
    columns: ColDef[],
    checkboxes
  ) => {
    return columns.map((column) => {
      return {
        ...column,
        hide:
          column.field !== actionsColumnName ? checkboxes[column.field] : false,
      };
    });
  };

  // Add the visibility to the columns using the active headers checkboxes
  const columnsWithVisibility = getVisibleColumnsBasedOnCheckboxes(
    columns,
    checkboxes
  );

  const onColumnResized = (params) => {
    if (params.finished) {
      saveColumns(params.columnApi.getColumnState());
    }
  };

  // Variable used to store the visible columns at each moment
  let currentVisibleColumns = [];
  const onDisplayedColumnsChanged = (params) => {
    const displayedColumnNames = getColumnsOrder(params);
    saveColumnsConfiguration(
      params,
      currentVisibleColumns,
      displayedColumnNames,
      saveColumns
    );
    currentVisibleColumns = displayedColumnNames;
  };

  const onSortChanged = (params) => {
    // Save columns when sorting changes
    saveColumns(params.columnApi.getColumnState());
  };

  gridOptions = {
    ...gridOptions,
    columnDefs: columnsWithVisibility,
    defaultColDef: {
      floatingFilter: true,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    onSortChanged,
    onDisplayedColumnsChanged,
    onColumnResized,
    rowHeight: 40,
    suppressDragLeaveHidesColumns: true,
    animateRows: true,
    maintainColumnOrder: true,
    rowClassRules: rowClassRules,
    components: {
      actionsColumn: TableActionColumn,
    },
    context: context,
    skipHeaderOnAutoSize: true,
  };

  const domLayout = adaptToContainerHeight ? undefined : "autoHeight";
  return (
    <div className="ag-theme-balham-and-sidebar">
      <div className="ag-theme-balham">
        <AgGridReact
          getRowId={(data: any) => data.data.id}
          gridOptions={gridOptions}
          domLayout={domLayout}
          rowData={rowData}
        ></AgGridReact>
      </div>
      <TableSideBar
        columns={columnsWithVisibility}
        checkBoxes={checkboxes}
        toggleVisibility={toggleVisibility}
      />
    </div>
  );
};
