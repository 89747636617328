import { createUseItems } from "../configuration/fetchHooks";
import createConjunctionsFetcher from "../conjunctions/conjunctions";
import { createUseFetchWithConfig } from "../helpers/hooks";
import createItemFetcher from "../helpers/item";

export interface OPMConfig {
  id: number;
}

const opmUrlBuilder = ({ id }: OPMConfig) => {
  return `/api/v1/opms/${id}`;
};
const opmId = ({ id }: OPMConfig) => {
  return `opm_${id}`;
};
export const opm = createItemFetcher<OPMConfig, any>(
  opmUrlBuilder,
  "OPM",
  opmId
);
export const useOpm = createUseFetchWithConfig<OPMConfig, any>(
  opm,
  (state) => state.opm
);

const opmConjunctionsUrlBuilder = (config) =>
  `api/v1/opms/${config?.id}/postcamconjunctions`;
export const opmConjunctions = createConjunctionsFetcher({
  storeId: "OPM_CONJUNCTIONS",
  computeURL: opmConjunctionsUrlBuilder,
});
export const useOpmConjunctions = createUseItems(
  opmConjunctions,
  (state) => state.opmConjunctions
);
