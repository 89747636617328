import * as React from "react";

import { Icon } from "@blueprintjs/core";
import { HashRouter, Link } from "react-router-dom";
import { TABLE_ACTION } from "./configurations";


interface TableActionColumnProps {
  context: {
    actions: TABLE_ACTION[];
    base: string;
    source: string;
    downloadData: (param: { id: string }) => void;
  };
  data: {
    id: string;
    plotFiles?: string;
    event?: { plotFiles?: string };
  };
}

export const TableActionColumn: React.FC<TableActionColumnProps> = ({
  context,
  data,
}) => {
  const buildAction = (action: TABLE_ACTION) => {
    switch (action) {
      case TABLE_ACTION.INFO:
        return (
          <Link
            to={`/${context.base}/${data.id}/info/`}
            key={`link-info-${data.id}`}
          >
            <Icon iconSize={16} icon="eye-open" className="icons-table" />
          </Link>
        );
      case TABLE_ACTION.DATABASE:
        return (
          <Link
            to={`/${context.base}/${context.source}/${data.id}/info/`}
            key={`link-db-${data.id}`}
          >
            <Icon
              iconSize={16}
              icon="database"
              className="icons-table"
              key={`icon-db-${data.id}`}
            />
          </Link>
        );
      case TABLE_ACTION.PLOTS:
        if (
          data.plotFiles === "AVAILABLE" ||
          data.event?.plotFiles === "AVAILABLE"
        ) {
          return (
            <Icon
              iconSize={16}
              icon="timeline-line-chart"
              className="icons-table"
              title="plots"
              key={`icon-plot-${data.id}`}
            />
          );
        }
        return (
          <Icon
            key={`icon-plot-${data.id}`}
            iconSize={16}
            icon="timeline-line-chart"
            className="icons-table disabled-icon-link"
            title="plots"
          />
        );
      case TABLE_ACTION.DOWNLOAD:
        return (
          <Icon
            key={`icon-download-${data.id}`}
            iconSize={16}
            icon="download"
            onClick={() => context.downloadData({ id: data.id })}
            className="icons-table foc-clickable"
          />
        );
    }
  };

  if (data != null) {
    const actionButtons = context.actions.map((action) => buildAction(action));
    return (
      <HashRouter>
        <div>{actionButtons}</div>
      </HashRouter>
    );
  } else {
    return null;
  }
};
