import React from "react";
import { Label } from "../Label";

interface OrbitDetailsProps {
  orbit: {
    operator?: { value: string };
    creationDate?: { value: string };
    startDate?: { value: string };
    endDate?: { value: string };
    sourceOfInformation?: { value: string };
    orbitFile?: { value: string };
    spaceObject?: {
      cosparId?: { value: string };
      noradId?: { value: string };
      name?: { value: string };
    };
  };
}

export const OrbitDetails: React.FC<OrbitDetailsProps> = ({ orbit }) => {
  return (
    <div className="datainside" style={{ padding: "25px 0px" }}>
      <div className="datainside-left" style={{ paddingRight: "30px" }}>
        <Label title={"Original filename: "} value={orbit?.orbitFile} />
        <Label
          title={"Operator: "}
          value={orbit?.operator?.value?.toUpperCase()}
        />
        <Label title={"Creation date: "} value={orbit?.creationDate} />
        <Label title={"Start time: "} value={orbit?.startDate} />
        <Label title={"End time: "} value={orbit?.endDate} />
        <Label title={"Source: "} value={orbit?.sourceOfInformation} />
      </div>
      <div
        className="datainside-right"
        style={orbit?.spaceObject ? { display: "block" } : { display: "none" }}
      >
        <Label title={"Cospar ID: "} value={orbit?.spaceObject?.cosparId} />
        <Label title={"Norad ID: "} value={orbit?.spaceObject?.noradId} />
        <Label title={"Name: "} value={orbit?.spaceObject?.name} />
      </div>
    </div>
  );
};
