import { actionTypes } from "./actionTypes";

export const uploadFile = (payload) => {
  return {
    type: actionTypes.UPLOAD,
    payload,
  };
};

export const setUploadFilePending = (payload) => {
  return {
    type: actionTypes.UPLOAD_PENDING,
    payload,
  };
};

export const setUploadFileError = (payload, error) => {
  let content: any[] = [];
  for (const value of payload.files.entries()) {
    content.push(value.name);
  }
  return {
    type: actionTypes.UPLOAD_ERROR,
    payload: payload,
    error: error,
  };
};

export const setUploadFileFinish = (payload) => {
  return {
    type: actionTypes.UPLOAD_FINISH,
    payload,
  };
};

export const setUploadFileDelete = () => {
  return {
    type: actionTypes.UPLOAD_DELETE,
    payload: {},
  };
};

export const downloadFile = (config) => {
  return {
    type: actionTypes.DOWNLOAD,
    payload: config,
  };
};

export const setDownloadFilePending = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_PENDING,
    payload,
  };
};

export const setDownloadFileError = (payload, error) => {
  return {
    type: actionTypes.DOWNLOAD_ERROR,
    payload: { ...payload, error },
  };
};

export const setDownloadFileFinish = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_FINISH,
    payload,
  };
};

export const downloadFileZip = (config) => {
  return {
    type: actionTypes.DOWNLOAD_ZIP,
    payload: config,
  };
};

export const setDownloadFileZipPending = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_ZIP_PENDING,
    payload,
  };
};

export const setDownloadFileZipError = (payload, error) => {
  return {
    type: actionTypes.DOWNLOAD_ZIP_ERROR,
    payload: { ...payload, error },
  };
};

export const setDownloadFileZipFinish = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_ZIP_FINISH,
    payload,
  };
};

export type Actions =
  | ReturnType<typeof uploadFile>
  | ReturnType<typeof setUploadFilePending>
  | ReturnType<typeof setUploadFileError>
  | ReturnType<typeof setUploadFileFinish>
  | ReturnType<typeof downloadFile>
  | ReturnType<typeof setDownloadFilePending>
  | ReturnType<typeof setDownloadFileError>
  | ReturnType<typeof setDownloadFileFinish>
  | ReturnType<typeof downloadFileZip>
  | ReturnType<typeof setDownloadFileZipPending>
  | ReturnType<typeof setDownloadFileZipError>
  | ReturnType<typeof setDownloadFileZipFinish>
  | ReturnType<typeof setUploadFileDelete>;
