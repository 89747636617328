import { combineEpics, createEpicMiddleware } from "redux-observable";
import webSocket from "@focus/services/lib/webSocketWrapper";
import _ajax, { authErrorEpic } from "./helpers/ajaxWrapper";
import { Duck } from "@focus/interfaces/lib/common/Duck";

function getMiddlewares(duck: Duck) {
  return Object.keys(duck.middleware).map(key => duck.middleware[key]);
}
/**
 * duck configuration object
 */
type Ducks = {
  [key: string]: Duck;
};
// Combine the epics before generating the final middleware
// we inject the ajax service so it is easier to test
function createMiddleware(ducks: Ducks, stomp?) {
  const rootEpic = (...args) => {
    const epics = [authErrorEpic];
    const extended = Object.keys(ducks)
      .map(key => getMiddlewares(ducks[key]))
      .reduce((prev, current) => [...prev.concat(...current)], epics);
    return combineEpics(...extended)(...args, {
      ajax: _ajax(),
      stomp,
      webSocket
    });
  };
  return createEpicMiddleware(rootEpic);
}

export default createMiddleware;
