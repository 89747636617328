import { Observable } from "rxjs";
import { addNotification } from "@focus/services/lib/toaster/actions";
import { actionTypes } from "./actionTypes";
import { Actions } from "./actions";
import { Intent } from "@blueprintjs/core";


const mapAction2Notification = (action: Actions) => {
    switch (action.type) {
        case actionTypes.DOWNLOAD_ERROR:
            return addNotification({
                intent: Intent.DANGER,
                message: `Error downloading orbit file for orbit ${action.payload.id}: 
                ${action.payload.error}`
            });
        case actionTypes.DOWNLOAD_FINISH:
            return addNotification({
                intent: Intent.SUCCESS,
                message: `Orbit file downloaded for orbit ${action.payload.id}`
            });
        case actionTypes.DOWNLOAD_ZIP_ERROR:
            return addNotification({
                intent: Intent.DANGER,
                message: `Error downloading compressed orbit files for conjunction ${action.payload.id}:
                    ${action.payload.error}`
            });
        case actionTypes.DOWNLOAD_ZIP_FINISH:
            return addNotification({
                intent: Intent.SUCCESS,
                message: `Orbit files downloaded for conjunction ${action.payload.id}`
            });
        default:
            return undefined;
    }
}

export function createNotificationsEpicForAction() {
    return actions$ =>
        actions$
            .ofType(
                actionTypes.DOWNLOAD_ERROR,
                actionTypes.DOWNLOAD_FINISH,
                actionTypes.DOWNLOAD_ZIP_ERROR,
                actionTypes.DOWNLOAD_ZIP_FINISH
            )
            .mergeMap(action => Observable.of(mapAction2Notification(action)));
}