import { Duck } from "@focus/interfaces/lib/common/Duck";
import { combineReducers } from "redux";

interface DucksConfiguration {
  [key: string]: Duck;
}
/**
 * Combine Duck in parallel i.e each within its own key
 *
 * @param ducksConfig a series of ducks to combine in an object
 */
export function combineDucks(ducksConfig: DucksConfiguration) {
  // actions and middleware are independent of the duck key
  const actions: any = Object.keys(ducksConfig).reduce(
    (accumulator, currentKey) => {
      const duck: Duck = ducksConfig[currentKey];
      return { ...accumulator, [currentKey]: duck.actions };
    },
    {}
  );
  const middleware = Object.keys(ducksConfig).reduce(
    (accumulator, currentKey) => {
      const duck: Duck = ducksConfig[currentKey];
      return [...accumulator, ...duck.middleware];
    },
    []
  );
  // the reducer and selector take into account the substate for each of the reducers
  const reducersFromDucks = Object.keys(ducksConfig).reduce(
    (accumulator, currentKey) => {
      const duck: Duck = ducksConfig[currentKey];
      return { ...accumulator, [currentKey]: duck.reducer };
    },
    {}
  );
  // use the combine reducers function from redux
  const reducer = combineReducers(reducersFromDucks);
  // wrap the actions taking into account the state key for each duck
  const selectorsFromDucks = Object.keys(ducksConfig).reduce(
    (accumulator, currentKey) => {
      const duck: Duck = ducksConfig[currentKey];
      return { ...accumulator, [currentKey]: duck.selectors };
    },
    {}
  );
  const selectors: any = combineSelectors(selectorsFromDucks);
  // return the duck
  return { actions, reducer, selectors, middleware };
}
type Selector = (state: any, ...args) => any;
interface KeyedSelectors {
  [key: string]: {
    [key: string]: Selector;
  };
}
/**
 * Combine selector with a nested state
 * @param keyedSelectors
 */
function combineSelectors(keyedSelectors: KeyedSelectors) {
  function wrapSelector(key: string, selector: Selector) {
    return function(state, ...args) {
      return selector(state[key], ...args);
    };
  }
  const wrappedSelectors = Object.keys(keyedSelectors).reduce(
    (accumulator, currentKey) => {
      const selectors = keyedSelectors[currentKey];
      const wrappedSelectorForDuck = Object.keys(selectors).reduce(
        (accumulated, selectorKey) => ({
          ...accumulated,
          [selectorKey]: wrapSelector(currentKey, selectors[selectorKey])
        }),
        {}
      );
      return { ...accumulator, [currentKey]: wrappedSelectorForDuck };
    },
    {}
  );
  return wrappedSelectors;
}
