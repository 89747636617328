import { CustomLabel, SelectMenu } from "@focus/focusUI";
import { ColDef } from "ag-grid-community";
import * as React from "react";
import { TABLE_ACTION } from "../table/configurations";
import { FiniteTable } from "../table/FiniteTable";

interface OperatorFleetTableProps {
  downloadZipOrbits: (param: { id: string }) => void;
  saveFleetConjunctionColumns: () => void;
  setStateFleetConjunctionsFilter: (param: { filter: object }) => void;
  getStateFleetConjunctionsFilter: () => { filter: object };
  setNumberOfConjunctionsInTitle: (param: number) => void;
  visibleColumns: any[];
  columnDefinitions: ColDef[];
  conjunctions: { operator: string; fleetConjunctions: any[] }[];
}

export const OperatorFleetTable: React.FunctionComponent<
  OperatorFleetTableProps
> = ({
  downloadZipOrbits,
  saveFleetConjunctionColumns,
  setStateFleetConjunctionsFilter,
  getStateFleetConjunctionsFilter,
  setNumberOfConjunctionsInTitle,
  visibleColumns,
  columnDefinitions,
  conjunctions,
}) => {
  const elements = {};
  const operators = [];

  conjunctions.forEach((c) => {
    operators.push(c.operator);
    elements[c.operator] = c.fleetConjunctions;
  });

  const [selectedOperator, setOperator] = React.useState(operators[0]);
  const [operatorConjunctions, setOperatorConjunctions] = React.useState(
    elements[operators[0]]
  );

  React.useEffect(() => {
    setOperatorConjunctions(elements[selectedOperator]);

    return () => {
      setOperatorConjunctions(undefined);
    };
  }, [elements, selectedOperator]);

  React.useEffect(() => {
    setNumberOfConjunctionsInTitle(operatorConjunctions.length);
    return () => {
      setNumberOfConjunctionsInTitle(undefined);
    };
  }, [operatorConjunctions]);

  return (
    <div className="w-100 h-100 vertical-flex">
      <CustomLabel text="Operator" className="w-100">
        <SelectMenu
          onChange={setOperator}
          value={selectedOperator}
          options={operators}
        />
      </CustomLabel>
      <FiniteTable
        rowData={operatorConjunctions}
        saveColumns={saveFleetConjunctionColumns}
        columnDefinitions={columnDefinitions}
        setFilter={setStateFleetConjunctionsFilter}
        getFilter={getStateFleetConjunctionsFilter}
        activeColumns={visibleColumns}
        base={"fleet"}
        actions={[TABLE_ACTION.DOWNLOAD, TABLE_ACTION.INFO, TABLE_ACTION.PLOTS]}
        downloadData={downloadZipOrbits}
        rowClassRules={{
          "row-alert": (params) =>
            params.api.getValue("riskLevel.value", params.node) === "ALERT",
          "row-warning": (params) =>
            params.api.getValue("riskLevel.value", params.node) === "WARNING",
        }}
      />
    </div>
  );
};
