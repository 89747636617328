import * as React from "react";

import { StatusProxy } from "@focus/focusUI";
import { GraphsTabs } from "@focus/focusGrafos";
import { GraphDataProvider } from "@focus/focusGrafos/lib/DataHandler";
import { GraphConfigurationProvider } from "@focus/focusGrafos/lib/ConfigurationHandler";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";

import { useGrafosConfig, useGrafosDat } from "../../services/grafos/index";
import { FolderType } from "@focus/focusGrafos/lib/interfaces/config";

interface GrafosBuilderProps {
  id: number;
}

export const GrafosBuilder: React.FC<GrafosBuilderProps> = ({ id }) => {
  const getProxyStatus = (configStatus: ItemStatus, datStatus: ItemStatus) => {
    if (configStatus === ItemStatus.ERROR || datStatus === ItemStatus.ERROR) {
      return ItemStatus.ERROR;
    } else if (
      configStatus === ItemStatus.PENDING ||
      datStatus === ItemStatus.PENDING
    ) {
      return ItemStatus.PENDING;
    } else {
      return ItemStatus.READY;
    }
  };

  const getProxyError = (configError: string, datError: string) => {
    if (
      configError &&
      configError.length > 0 &&
      datError &&
      datError.length > 0
    ) {
      return configError + "\n" + datError;
    } else {
      return configError + datError;
    }
  };

  const grafosConfig = React.useMemo(() => ({ id }), [id]);
  // Fetch the grafos config
  const {
    status: configStatus,
    error: configError,
    value: configValue,
  } = useGrafosConfig(grafosConfig);
  // Fetch the grafos dat
  const {
    status: datStatus,
    error: datError,
    value: datValue,
  } = useGrafosDat(grafosConfig);

  const getGrafosTabs = (configValue) => {
    let tabs = [];
    if (configValue?.selectorFolderList) {
      tabs = configValue.selectorFolderList.flatMap((selector) =>
        selector.selectorList.flatMap((disp) => disp.tabs.map((tab) => tab))
      );
    }
    return tabs;
  };
  
  // Get all the available tabs from their location in the
  // configValue object
  const tabs = getGrafosTabs(configValue);
  
  return (
    <StatusProxy
      status={getProxyStatus(configStatus, datStatus)}
      error={getProxyError(configError, datError)}
    >
      <div
        className="foc-detail-main-content"
        style={{
          marginBottom: "50px",
          height: "600px",
        }}
      >
        <GraphConfigurationProvider initialData={configValue}>
          <GraphDataProvider initialData={datValue}>
            <div
              style={{
                width: "85%",
                margin: "auto",
                height: "600px",
                position: "relative",
              }}
            >
              <GraphsTabs
                folderType={FolderType.Defined}
                data={datValue}
                tabs={tabs}
              />
            </div>
          </GraphDataProvider>
        </GraphConfigurationProvider>
      </div>
    </StatusProxy>
  );
};
