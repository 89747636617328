import { Icon, Tab, Tabs } from "@blueprintjs/core";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React from "react";
// import "../../assets/stylesheets/ag-grid_styles.css";

import OrbitConjunctionsTableContainer from "../../containers/OrbitConjunctionsTableContainer";
import { useConfig } from "../../services/helpers/hooks";
import { useOrbitDetail } from "../../services/orbits";
import { OrbitDetails } from "./OrbitDetails";
import { usePlotsConfig } from "../../services/configuration";
import { getNonCamPlots, groupPlots } from "../grafos/helpers";

interface OrbitInformationProps {
  match: {
    params: {
      [key:string]: string;
    };
  };
  history: {
    goBack: () => void;
  };
}
export const OrbitInformation: React.FC<OrbitInformationProps> = ({
  match,
  history,
}) => {
  const config = useConfig(match, "orbits");
  const fetchedOrbit = useOrbitDetail(config);
  const orbit = fetchedOrbit?.value;
  const [selectedTab, setSelectedTab] = React.useState(undefined);

  const parents = orbit === undefined ? undefined : [orbit];
  const plotsConfig = usePlotsConfig();
  const plotGroups = groupPlots(parents, plotsConfig?.items, "orbit");
  const nonCamPlots = getNonCamPlots(plotGroups);

  let plotsTab =
    nonCamPlots.length > 0 ? (
      <Tab
        id="plots"
        title="Plots"
        key="plots"
        panel={
          <div className="foc-tab-content center-aligned">{nonCamPlots}</div>
        }
      />
    ) : undefined;

  return (
    <div className="foc-content">
      <div className="foc-page_title">
        <span className="foc-clickable" onClick={history.goBack}>
          Orbit files
        </span>
        <Icon className="foc-page_icon" icon="chevron-right" iconSize={18} />
        <span>{match.params.id}</span>
      </div>
      <Tabs
        animate={true}
        id="orbit-detail-tabs"
        selectedTabId={selectedTab}
        onChange={(newTabId) => {
          setSelectedTab(newTabId);
        }}
      >
        <Tab
          id="details"
          title="Details"
          panel={
            <div className="orbit-info">
              <OrbitDetails orbit={orbit} />
              <OrbitConjunctionsTableContainer id={match.params.id} />
            </div>
          }
        />
        {plotsTab}
      </Tabs>
    </div>
  );
};
