import { connect } from "react-redux";
import { downloadFileZip } from "../services/downloaduploadOrbits/actions";
import { OrbitConjunctionsTable } from "../components/orbits/OrbitConjunctionsTable";
import { orbitConjunctions } from "../services/orbits";
import { orbitConjunctionsColumnsLayout } from "../services/configuration";

const orbitConjunctionActions = orbitConjunctions.actions;
const orbitConjunctionSelectors = orbitConjunctions.selectors;
const orbitConjunctionsLayoutActions = orbitConjunctionsColumnsLayout.actions;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  getStateOrbitConjunctionsFilter: () => {
    return orbitConjunctionSelectors.getFilter(state.orbitConjunctions);
  },
});

const mapDispatchToProps = (dispatch) => ({
  downloadZipOrbits: (payload) => {
    dispatch(downloadFileZip(payload));
  },
  saveOrbitConjunctionsInStore: (conjunctions, startIndex) => {
    const payload = { conjunctions, startIndex };
    return dispatch(orbitConjunctionActions.setConjunctions(payload));
  },
  setStateOrbitConjunctionsFilter: (filter) => {
    return dispatch(orbitConjunctionActions.setFilter(filter));
  },
  saveOrbitConjunctionColumns: (columns) => {
    return dispatch(orbitConjunctionsLayoutActions.saveItems(columns));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrbitConjunctionsTable);
