import { connect } from "react-redux";
import { downloadFileZip } from "../services/downloaduploadOrbits/actions";
import { ConjunctionsDatabase } from "../components/conjunctions/ConjunctionsDatabase";
import { conjunctions } from "../services/conjunctions";
import { conjunctionColumnsLayout } from "../services/configuration";

const conjunctionsActions = conjunctions.actions;
const conjunctionsSelectors = conjunctions.selectors;
const conjunctionsLayoutActions = conjunctionColumnsLayout.actions;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  conjunctionsFilter: conjunctionsSelectors.getFilter(state.conjunctions),
});

const mapDispatchToProps = (dispatch) => ({
  downloadZipOrbits: (payload) => {
    dispatch(downloadFileZip(payload));
  },
  saveConjunctionsFilter: (filter) => {
    return dispatch(conjunctionsActions.setFilter(filter));
  },
  saveConjunctionColumns: (columns) => {
    return dispatch(conjunctionsLayoutActions.saveItems(columns));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConjunctionsDatabase);
