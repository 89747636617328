
export const checkResponse = (response, requestedObject) => {
    if (response.status) {
      alert(
        `Failed requesting ${requestedObject} (${response.path}) : ${response.status} - ${response.error}`
      );
      return undefined;
    } else {
      return response;
    }
  };