import { Duck } from "@focus/interfaces/lib/common/Duck";

export interface OrbitConfig {
  source: string;
}

// export default function createOrbitsFetcher(orbit): Duck {
export default function createOrbitsFetcher(): Duck {
  // define constants
  const SET_FILTER = "ORBITS/SET_FILTER";

  // ACTIONS
  /**
   * @param  {} payload payload with the new applied filter
   */
  function setFilter(payload: object) {
    return { type: SET_FILTER, payload };
  }

  // REDUCER
  // default state
  const initialState = {
    filter: {},
  };
  /**
   * Reducer to handle the orbits
   *
   * @param  {Object} state={}
   * @param  {Object} action
   */
  function reducer(state = initialState, action) {
    switch (action.type) {
      case SET_FILTER:
        // Update the filter information in the state
        return { ...state, filter: action.payload };
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * @param  {Object} state
   */
  function getFilter(state, config: OrbitConfig) {
    return state.filter;
  }

  const actionTypes = {
    SET_FILTER,
  };
  const actions = {
    setFilter,
  };
  const selectors = {
    getFilter,
  };
  const middleware = [];
  return { actions, reducer, middleware, selectors, actionTypes };
}
