import * as React from "react";
import Plot from "react-plotly.js";

import { StatusProxy } from "@focus/focusUI";
import { useGrafosJson } from "../../services/grafos/index";

import "../../assets/stylesheets/grafos.css";

interface GrafosJsonProps {
  id: number;
}

export const GrafosJson: React.FC<GrafosJsonProps> = ({ id }) => {
  const grafoId = React.useMemo(() => ({ id }), [id]);
  // Fetch the grafos JSON file
  const grafo = useGrafosJson(grafoId);

  return (
    <StatusProxy status={grafo.status} error={grafo.error}>
        <Plot
          className="plotly-plot"
          data={grafo.value?.data}
          layout={grafo.value?.layout}
          config={{ displaylogo: false }}
          useResizeHandler
        />
    </StatusProxy>
  );
};
