import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ConfigDetail } from "./fetchers";

/**
 * createUseDetail creates a hook that manages the fetching and handling of individuals
 * @param fetcher The fetcher for individual conjunctions
 * @param statePath A function that given the state, returns the correct path to the relevant object
 */
export const createUseDetail =
  (fetcher, statePath: (storeState: any) => any) => (config: ConfigDetail) => {
    const status = useSelector(
      (state) => fetcher.selectors.getStatus(statePath(state)),
      shallowEqual
    );
    const error = useSelector(
      (state) => fetcher.selectors.getError(statePath(state)),
      shallowEqual
    );
    const value = useSelector(
      (state) => fetcher.selectors.getContent(statePath(state)),
      shallowEqual
    );
    const dispatch = useDispatch();

    useEffect(() => {
      // fetch on mount or config change
      dispatch(fetcher.actions.fetch(config));
    }, [config, dispatch]);
    return { status, error, value };
  };

export const useConfig = (match, urlContext) => {
  return useMemo(
    () => ({
      id: match.params.id,
      urlContext,
    }),
    [match, urlContext]
  );
};

export interface ItemFetchedConfig<T> {
  status: ItemStatus;
  error: string;
  value: T;
}

export const createUseFetchWithConfig =
  <T, R>(fetcher, statePath: (storeState: any) => any) =>
  (config: T) => {
    const status = useSelector(
      (state) => fetcher.selectors.getStatus(statePath(state), config),
      shallowEqual
    );
    const error = useSelector(
      (state) => fetcher.selectors.getItemError(statePath(state), config),
      shallowEqual
    );
    const value = useSelector(
      (state) => fetcher.selectors.getItem(statePath(state), config),
      shallowEqual
    );

    const dispatch = useDispatch();
    const prevConfig = useRef<T | null>(null);
    // fetch item on mount, delete on unmount
    useEffect(() => {
      // fetch on mount or config change
      dispatch(fetcher.actions.fetchItem(config));
      // clean prevConfig on config change
      if (prevConfig.current) {
        dispatch(fetcher.actions.deleteItem(prevConfig.current));
      }
      prevConfig.current = config;
      // clean on unmount
      return () => {
        dispatch(fetcher.actions.deleteItem(config));
      };
    }, [config]);
    return { status, error, value } as ItemFetchedConfig<R>;
  };

export const createUseFetch =
  <R>(fetcher, statePath: (storeState: any) => any) =>
  () => {
    const status = useSelector(
      (state) => fetcher.selectors.getStatus(statePath(state)),
      shallowEqual
    );
    const error = useSelector(
      (state) => fetcher.selectors.getItemError(statePath(state)),
      shallowEqual
    );
    const value = useSelector(
      (state) => fetcher.selectors.getItem(statePath(state)),
      shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetcher.actions.fetchItem());
      return () => {
        dispatch(fetcher.actions.deleteItem());
      };
    }, []);

    return { status, error, value } as ItemFetchedConfig<R>;
  };
