import React from "react";
import { StatusProxy } from "@focus/focusUI";
import { CDMViewer } from "../components/conjunctions/CDMViewer";
import { useCdmData, useCdmInfo } from "../services/cdm";

/** Component that fetches the information required to show a CDM and renders a children with it. Used to abstract  */
export const CDMContainer = ({ cdmId }: { cdmId: string }) => {
  const {
    error: cdmError,
    status: cdmStatus,
    value: ndmData,
  } = useCdmData(cdmId);
  const {
    error: CDMContentError,
    status: CDMContentStatus,
    value: CDMContentValue,
  } = useCdmInfo(cdmId);
  const isAllReady = !!ndmData && CDMContentValue;

  return (
    <StatusProxy status={CDMContentStatus} error={CDMContentError}>
      <StatusProxy status={cdmStatus} error={cdmError}>
        {isAllReady && (
          <CDMViewer cdm={ndmData} cdmContent={CDMContentValue} />
        )}
      </StatusProxy>
    </StatusProxy>
  );
};
