import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useEffect } from "react";

export const createUseItems =
  (fetcher, statePath: (storeState: any) => any) => (params?) => {
    const status = useSelector(
      (state) => {
        return fetcher.selectors.getStatus(statePath(state))},
      shallowEqual
    );
    const error = useSelector(
      (state) => fetcher.selectors.getError(statePath(state)),
      shallowEqual
    );
    const items = useSelector(
      (state) => fetcher.selectors.getItems(statePath(state)),
      shallowEqual
    );
    const dispatch = useDispatch();

    // fetch item on mount
    useEffect(() => {
      dispatch(fetcher.actions.fetchItems(params));
    }, []);

    return { status, error, items };
  };
