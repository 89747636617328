import { connect } from "react-redux";
import { OrbitsDatabase } from "../components/orbits/OrbitsDatabase";
import { orbitColumnsLayout } from "../services/configuration";
import { downloadFile } from "../services/downloaduploadOrbits/actions";
import { orbits } from "../services/orbits";

const orbitsActions = orbits.actions;
const orbitsSelectors = orbits.selectors;
const orbitLayoutActions = orbitColumnsLayout.actions;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  orbitsFilter: orbitsSelectors.getFilter(state.orbits),
});

const mapDispatchToProps = (dispatch) => ({
  downloadOrbits: (payload) => {
    dispatch(downloadFile(payload));
  },
  saveOrbitsFilter: (filter) => {
    return dispatch(orbitsActions.setFilter(filter));
  },
  saveOrbitColumns: (columns) => {
    return dispatch(orbitLayoutActions.saveItems(columns));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrbitsDatabase);
