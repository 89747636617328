export const CONJ_DEFAULT_VISIBLE_COLUMNS = [
  "eventIdentifier.value",
  "conjunctionIdentifier.value",
  "timeOfCloseApproach.value",
  "missDistance.value",
  "collisionProbability.value",
  "analysisTimeStart.value",
  "analysisTimeEnd.value",
  "targetName.value",
  "chaserName.value",
  "dangerousConjunction.value",
];

export const OPS_ACTIVE_HEADERS = [
  "spaceObject.cosparId.value",
  "startDate.value",
  "endDate.value",
  "executionTime.value",
];
export const CAT_ACTIVE_HEADERS = ["spaceObject.cosparId.value"];
