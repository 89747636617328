export const parseSort = (sort) => {
    if (sort && sort.length > 0) {
        return `&sort=${readSort(sort)}`;
    }
    return "";
  }

const readSort = (unhandledSorts) => {
    for (const sortElement in unhandledSorts) {
      const column = unhandledSorts[sortElement].colId;
      const sortValue = unhandledSorts[sortElement].sort;
      return `${column}:${sortValue}`;
    }
};