import React from "react";
import { applyFormat, getElementDescendant } from "../../helpers/utils";
import { Label } from "../Label";

interface ConjunctionElementProps {
  conjunction: any;
  orbitElement: string;
  formatters: any;
}

const getElementTitle = (element) => {
  return `${element.slice(0, 1).toUpperCase()}${element.slice(1)} Object`;
};

export const ConjunctionElement: React.FC<ConjunctionElementProps> = ({
  conjunction,
  orbitElement,
  formatters,
}) => {
  return (
    <div className="datainside-item">
      <div className="foc-section_title">{getElementTitle(orbitElement)}</div>
      <Label
        key={`${orbitElement}-cosparid`}
        title={"Cospar Id: "}
        value={getElementDescendant(conjunction, [
          orbitElement,
          "spaceObject",
          "cosparId",
        ])}
      />
      <Label
        key={`${orbitElement}-name`}
        title={"Name: "}
        value={getElementDescendant(conjunction, [
          orbitElement,
          "spaceObject",
          "name",
        ])}
      />
      <Label
        key={`${orbitElement}-noradid`}
        title={"Norad Id: "}
        value={getElementDescendant(conjunction, [
          orbitElement,
          "spaceObject",
          "noradId",
        ])}
      />
      <Label
        key={`${orbitElement}-soi`}
        title={"Source of Information: "}
        value={getElementDescendant(conjunction, [
          orbitElement,
          "sourceOfInformation",
        ])}
      />
      <Label
        key={`${orbitElement}-updatetime`}
        title={"Update Time: "}
        value={getElementDescendant(conjunction, [
          `${orbitElement}OrbitUpdateTime`,
        ])}
      />
      <Label
        key={`${orbitElement}-argperigee`}
        title={"Arg Perigee: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}ArgPerigee.value`,
          formatters
        )}
      />
      <Label
        key={`${orbitElement}-ascendingnode`}
        title={"Ascending Node: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}AscendingNode.value`,
          formatters
        )}
      />
      <Label
        key={`${orbitElement}-ecc`}
        title={"Eccentricity: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}Eccentricity.value`,
          formatters
        )}
      />
      <Label
        key={`${orbitElement}-incl`}
        title={"Inclination: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}Inclination.value`,
          formatters
        )}
      />
      <Label
        key={`${orbitElement}-sma`}
        title={"Semimajor Axis: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}SemimajorAxis.value`,
          formatters
        )}
      />
      <Label
        key={`${orbitElement}-ta`}
        title={"True Anomaly: "}
        value={applyFormat(
          conjunction,
          `${orbitElement}TrueAnomaly.value`,
          formatters
        )}
      />
      <div className="datainside">
        <div className="datainside-item-mini">
          <Label
            key={`${orbitElement}-covnn`}
            title={"Cov NN: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovNN.value`,
              formatters
            )}
          />
          <Label
            key={`${orbitElement}-covtn`}
            title={"Cov TN: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovTN.value`,
              formatters
            )}
          />
          <Label
            key={`${orbitElement}-covtt`}
            title={"Cov TT: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovTT.value`,
              formatters
            )}
          />
        </div>
        <div className="datainside-item-mini">
          <Label
            key={`${orbitElement}-covrn`}
            title={"Cov RN: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovRN.value`,
              formatters
            )}
          />
          <Label
            key={`${orbitElement}-covrt`}
            title={"Cov RT: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovRT.value`,
              formatters
            )}
          />
          <Label
            key={`${orbitElement}-covrr`}
            title={"Cov RR: "}
            value={applyFormat(
              conjunction,
              `${orbitElement}CovRR.value`,
              formatters
            )}
          />
        </div>
      </div>
    </div>
  );
};
