import React from "react";
import {
  OPS_ACTIVE_HEADERS,
  CAT_ACTIVE_HEADERS,
} from "../../services/conjunctions/Headers";
import { COLUMNDEFS_OPS, COLUMNDEFS_CAT } from "./configurations";
import { TABLE_ACTION } from "../table/configurations";
import { InfiniteTable } from "../table/InfiniteTable";

import { StatusProxy } from "@focus/focusUI";
import { useSavedOrbitColumns } from "../../services/configuration";

import { getOrbitsPage, getOrbitRows } from "../../services/orbits";
import { getVisibleColumns } from "../../services/helpers/headers";
import { ACTIONS_COLDEF } from "../../services/helpers/headers";
export enum SATORBIT_TAB {
  OPS = "OPS",
  CAT = "CAT",
}

interface OrbitsDatabaseTabProps {
  history: any;
  location: any;
  page?: number;
  source: SATORBIT_TAB;
  downloadOrbits: (param: { id: string }) => void;
  orbitsFilter: object;
  saveOrbitColumns: (param: object) => void;
  saveOrbitsFilter: (param: object) => void;
}

export const OrbitsDatabaseTab: React.FC<OrbitsDatabaseTabProps> = ({
  history,
  location,
  source,
  page,
  downloadOrbits,
  saveOrbitColumns,
  saveOrbitsFilter,
  orbitsFilter,
}) => {
  const buildColumns = () => {
    const columns = [ACTIONS_COLDEF];
    switch (source) {
      case SATORBIT_TAB.OPS:
        return [...columns, ...COLUMNDEFS_OPS];
      case SATORBIT_TAB.CAT:
        return [...columns, ...COLUMNDEFS_CAT];
      default:
        return columns;
    }
  };

  const getActiveHeaders = () => {
    let defaultActiveHeaders = [];
    switch (source) {
      case SATORBIT_TAB.OPS:
        defaultActiveHeaders = OPS_ACTIVE_HEADERS;
        break;
      case SATORBIT_TAB.CAT:
        defaultActiveHeaders = CAT_ACTIVE_HEADERS;
        break;
    }
    return defaultActiveHeaders;
  };

  const dataColumns = buildColumns();
  const activeHeaders = getActiveHeaders();

  // Request the user active columns to the server's DB
  let { status, error, items } = useSavedOrbitColumns();

  // If no headers are available in the DB, load the default active columns
  const visibleColumns = getVisibleColumns(items, activeHeaders, dataColumns);

  return (
    <StatusProxy status={status} error={error}>
      <InfiniteTable
        history={history}
        location={location}
        rowsGetter={getOrbitsPage}
        getNumberOfRows={getOrbitRows}
        saveColumns={saveOrbitColumns}
        filter={orbitsFilter}
        saveFilter={saveOrbitsFilter}
        initialPage={page}
        columnDefinitions={dataColumns}
        activeColumns={visibleColumns}
        source={source}
        base={"orbits"}
        actions={[TABLE_ACTION.DOWNLOAD, TABLE_ACTION.INFO, TABLE_ACTION.PLOTS]}
        downloadData={downloadOrbits}
      />
    </StatusProxy>
  );
};
