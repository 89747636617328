import * as React from "react";
import { Link } from "react-router-dom";
import { Menu, IconName, Tooltip, Position } from "@blueprintjs/core";
import { Route } from "react-router-dom";
import { useServerConfig } from "@focus/services/lib/serverConfig";
import "./AppsMenu.scss";
import { focusOcApps } from "./sideBarItems";

const LinkedMenuItem = ({ text, to, icon, collapsed }) => {
  const iconClass = `focus-menu-icon bp4-icon-${icon}`;
  const baseClass = collapsed
    ? "focus-lateral-item-collapsed bp4-menu-item"
    : "bp4-menu-item bp4-popover-dismiss";
  return (
    <Route
      path={to}
      children={({ match }) => {
        const isActive = match !== null;
        return (
          <li>
            <Tooltip
              content={text}
              targetClassName="focus-full-width"
              position={Position.RIGHT}
              hoverOpenDelay={500}
            >
              <Link
                to={to}
                className={`focus-lateral-item ${baseClass} ${iconClass} ${
                  isActive ? "focus-lateral-active" : ""
                }`}
              >
                {collapsed ? "" : text}
              </Link>
            </Tooltip>
          </li>
        );
      }}
    />
  );
};

export interface AppDescription {
  link: string;
  icon: IconName;
  text: string;
  id: string;
}

interface AppsMenuProps {
  collapsed: boolean;
  apps?: AppDescription[];
}

const readAvailableConfigurations = ({ configurations }) => {
  const availableAppsNames = configurations
    .filter((conf) => conf.available)
    .map((available) => available.name);
  return focusOcApps.filter((app) => availableAppsNames.includes(app.id));
};

export const AppsMenu: React.FC<AppsMenuProps> = ({ apps, collapsed }) => {
  const { value: config } = useServerConfig();
  const availableApps = readAvailableConfigurations(config);

  return (
    <Menu
      className={`bp4-large bp4-dark full-height focus-lateral-menu focus-dark-side ${
        collapsed ? "collapsed" : ""
      }`}
    >
      {availableApps.map((app) => (
        <LinkedMenuItem
          key={app.text}
          to={app.link}
          icon={app.icon}
          collapsed={collapsed}
          text={app.text}
        />
      ))}
    </Menu>
  );
};
