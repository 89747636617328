import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { Duck } from "@focus/interfaces/lib/common/Duck";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { Observable } from "rxjs";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";

export interface ConjunctionsFetcherConfig {
  storeId: string;
  computeURL: (any?) => string;
}

export default function createConjunctionsFetcher(
  config: ConjunctionsFetcherConfig
): Duck {
  const { storeId, computeURL } = config;

  // define constants
  const SET = `${storeId}/SET`;
  const SET_FILTER = `${storeId}/SET_FILTER`;
  const FETCH_START = `${storeId}/FETCH_START`;
  const FETCH_ERROR = `${storeId}/FETCH_ERROR`;
  const FETCH_PENDING = `${storeId}/FETCH_PENDING`;

  // ACTIONS
  function setItems(payload) {
    return { type: SET, payload };
  }
  function setFilter(payload: object) {
    return { type: SET_FILTER, payload };
  }
  function fetchItems(payload: any) {
    return { type: FETCH_START, payload };
  }
  function setError(payload: string) {
    return { type: FETCH_ERROR, payload };
  }
  function setPending() {
    return { type: FETCH_PENDING };
  }

  // REDUCER
  // default state
  const initialState = {
    items: [],
    status: ItemStatus.PENDING,
    errorMessage: "",
    filter: {},
  };

  /**
   * Reducer to handle the conjunction-related actions
   *
   * @param  {Object} state={}
   * @param  {Object} action
   */
  function reducer(state = initialState, action) {
    switch (action.type) {
      case SET_FILTER:
        // Update the filter information in the state
        return { ...state, filter: action.payload };
      case FETCH_PENDING:
        return {
          ...state,
          items: [],
          status: ItemStatus.PENDING,
          errorMessage: "",
        };
      case FETCH_ERROR:
        return {
          ...state,
          status: ItemStatus.ERROR,
          errorMessage: "",
          filter: {},
        };
      case SET:
        return {
          ...state,
          items: action.payload,
          status: ItemStatus.READY,
          errorMessage: "",
        };
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * @param  {Object} state
   */
  function getItems(state) {
    return state.items;
  }
  /**
   * @param  {Object} state
   */
  function getStatus(state) {
    return state.status;
  }
  /**
   * Get the error message if any
   * @param  {} state the state object
   */
  function getError(state) {
    return state.errorMessage;
  }
  /**
   * @param  {Object} state
   */
  function getFilter(state) {
    return state.filter;
  }

  const actionTypes = {
    SET_FILTER,
  };

  const actions = {
    setItems,
    setFilter,
    fetchItems,
  };

  const selectors = {
    getFilter,
    getStatus,
    getError,
    getItems,
  };

  const fetchItemsEpic = (action$, _, { ajax }) =>
    action$.ofType(FETCH_START).mergeMap((action) => {
      return ajax
        .getJSON(`${computeURL(action.payload)}`)
        .map((response) => {
          return setItems(response);
        })
        .catch((error: FocusAjaxError, source) =>
          Observable.of(setError(getErrorMessage(error)))
        )
        .startWith(setPending());
    });

  const middleware = [fetchItemsEpic];
  return { actions, reducer, middleware, selectors, actionTypes };
}
