import Keycloak from "keycloak-js";
import defConfig from "../keycloak.json";

const TOKEN_REFRESH_DELTA = 70;
const TOKEN_REFRESH_INTERVAL = 60000;

/**
 * KeycloakManager is a class in charge of the creation and administration
 * of the keycloak instance. It uses a static attribute to keep the instance,
 * and several static methods to encapsulate its utilities.
 */
export default class KeycloakManager {
  // The keycloak instance
  static keycloakInstance: Keycloak.KeycloakInstance;

  static refreshTokenInterval;

  /**
   * initKeycloak is responsible for the initialization of the keycloak instance.
   * ALWAYS call this function at the start of the app.
   * @param onAuthenticatedCallback The component or structure to show after the
   * authentication of the user, the App in our case.
   */
  static async initKeycloak(onAuthenticatedCallback) {
    // Fetch the keycloak config from the backend
    await fetch("/api/v1/authconfig")
      .then((response) => response.json())
      .then((config) => {
        // Create the instance with this config if everything went ok
        this.keycloakInstance = Keycloak(config);
      })
      .catch((error) => {
        // In case of error, alert the user and use the default config
        alert("Error while fetching Keycloak URL. Using default in its place.");
        console.warn(
          "Error while fetching Keycloak URL. Using default in its place.",
          error
        );
        this.keycloakInstance = Keycloak(defConfig);
      });
    // Start the keycloak instance
    this.keycloakInstance
      .init({
        onLoad: "login-required",
        pkceMethod: "S256",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        if (authenticated) {
          // If the user was authenticated correctly, render the React App
          onAuthenticatedCallback();
          this.refreshTokenInterval = setInterval(() => {
            // the token is updated only if it expires within the following 'TOKEN_REFRESH_DELTA' seconds
            this.keycloakInstance.updateToken(TOKEN_REFRESH_DELTA).catch(() => {
              console.error("Failed to refresh token");
            });
          }, TOKEN_REFRESH_INTERVAL);
        } else {
          // When not authenticated, show Keycloak login
          this.keycloakInstance.login();
        }
      })
      .catch((error) => {
        // In case of error, alert the user
        alert("Keycloak failed to initialize.");
        console.warn("Keycloak failed to initialize.", error);
      });
  }

  /**
   * doLogin encapsulates the keycloak instance login method
   */
  static doLogin() {
    return this.keycloakInstance.login();
  }

  /**
   * doLogout encapsulates the keycloak instance logout method
   */
  static doLogout() {
    if (this.refreshTokenInterval) {
      clearInterval(this.refreshTokenInterval);
    }
    return this.keycloakInstance.logout();
  }

  /**
   * getToken acts as the keycloak instance token getter
   */
  static getToken() {
    return this.keycloakInstance.token;
  }

  static getTokenOperators() {
    const parsedToken: any = this.keycloakInstance.tokenParsed;
    const groups: string[] = parsedToken.groups;
    const orgs = groups
      ?.filter((group) => group.startsWith("/organization_"))
      .map((group) => group.replace("/organization_", ""));
    if (orgs.length === 0) {
      orgs.push(parsedToken.preferred_username);
    }
    return orgs;
  }

  /**
   * getToken acts as the keycloak instance token setter
   * @param successCallback The function to be called if the token is valid.
   */
  static updateToken(successCallback) {
    return this.keycloakInstance
      .updateToken(5)
      .then(successCallback)
      .catch(this.doLogin);
  }

  /**
   * getUsername acts as the keycloak instance general user getter
   * @returns The instances token preferred_username or name
   */
  static getUsername() {
    const objKeycloak: any = this.keycloakInstance.tokenParsed;
    if (!objKeycloak.name) {
      return objKeycloak?.preferred_username;
    } else {
      return objKeycloak?.name;
    }
  }

  /**
   * getUserlogin acts as the keycloak instance user login getter
   * @returns The instances token preferred_username
   */
  static getUserlogin() {
    const objKeycloak: any = this.keycloakInstance.tokenParsed;
    return objKeycloak?.preferred_username;
  }
}
