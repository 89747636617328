import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import * as React from "react";
import "../../assets/stylesheets/ag-grid_styles.css";

import { StatusProxy } from "@focus/focusUI";
import {
  useAvailableconjunctionHeaders,
  useSavedConjunctionColumns,
} from "../../services/configuration";
import {
  getConjunctionRows,
  getConjunctionsPage,
} from "../../services/conjunctions";
import { CONJ_DEFAULT_VISIBLE_COLUMNS } from "../../services/conjunctions/Headers";
import {
  getColumnDefinitions,
  getVisibleColumns,
} from "../../services/helpers/headers";
import { TABLE_ACTION } from "../table/configurations";
import { InfiniteTable } from "../table/InfiniteTable";

interface ConjunctionsDatabaseTableProps {
  history: any;
  location: any;
  page?: number;
  downloadZipOrbits: (param: { id: string }) => void;
  saveConjunctionColumns: (param: object) => void;
  saveConjunctionsFilter: (param: { filter: object }) => void;
  conjunctionsFilter: object;
}

export const ConjunctionsDatabaseTable: React.FC<
  ConjunctionsDatabaseTableProps
> = ({
  history,
  location,
  page,
  downloadZipOrbits,
  saveConjunctionColumns,
  saveConjunctionsFilter,
  conjunctionsFilter,
}) => {
  // Request the user active columns to the server's DB
  const availableHeaders = useAvailableconjunctionHeaders();
  const savedColumnsLayout = useSavedConjunctionColumns();
  const columnDefinitions = getColumnDefinitions(availableHeaders.value, true);

  // If no headers are available in the DB, load the default active columns
  const visibleColumns = getVisibleColumns(
    savedColumnsLayout.items,
    CONJ_DEFAULT_VISIBLE_COLUMNS,
    columnDefinitions
  );

  return (
    <StatusProxy status={availableHeaders.status} error={availableHeaders.error}>
      <StatusProxy status={savedColumnsLayout.status} error={savedColumnsLayout.error}>
        <InfiniteTable
          history={history}
          location={location}
          rowsGetter={getConjunctionsPage}
          getNumberOfRows={getConjunctionRows}
          saveColumns={saveConjunctionColumns}
          filter={conjunctionsFilter}
          saveFilter={saveConjunctionsFilter}
          initialPage={page}
          columnDefinitions={columnDefinitions}
          activeColumns={visibleColumns}
          base={"conjunctions"}
          actions={[
            TABLE_ACTION.DOWNLOAD,
            TABLE_ACTION.INFO,
            TABLE_ACTION.PLOTS,
          ]}
          downloadData={downloadZipOrbits}
          rowClassRules={{
            "row-alert": (params) =>
              params.api.getValue("riskLevel.value", params.node) === "ALERT",
            "row-warning": (params) =>
              params.api.getValue("riskLevel.value", params.node) === "WARNING",
          }}
        />
      </StatusProxy>
    </StatusProxy>
  );
};
