export const parseFilter = (filter) => {
  if (filter && Object.keys(filter).length > 0) {
      return `&filter=${readFilter(filter)}`;
  }
  return "";
}

const readFilter = (unhandledFilters) => {
  const parsedFilters = [];
  for (const column in unhandledFilters) {
    if (unhandledFilters[column].type) {
      parsedFilters.push(`${column} ${handleSimpleFilter(unhandledFilters[column])}`
      );
    } else if (
      unhandledFilters[column].condition1 &&
      unhandledFilters[column].condition2
    ) {
      parsedFilters.push(
        `(${handleMultipleFilter(column, unhandledFilters[column])})`
      );
    }
  }
  let filters = "";
  parsedFilters.forEach(filter => {filters = filters.concat(';' + filter)});
  return filters.substring(1);
};

const handleSimpleFilter = (simpleFilter) => {
  let parsedFilter;
  switch (simpleFilter.filterType) {
    case "date":
      parsedFilter = handleDateFilter(simpleFilter);
      break;
    case "number":
      parsedFilter = handleNumberFilter(simpleFilter);
      break;
    default:
      parsedFilter = `${simpleFilter.type} "${simpleFilter.filter}"`;
  }
  return parsedFilter;
};

const handleDateFilter = (filter) => {
  if (filter.type === "inRange") {
    return `${filter.type} ${filter.dateFrom}_${filter.dateTo}`;
  } else {
    return `${filter.type} ${filter.dateFrom}`;
  }
};

const handleNumberFilter = (filter) => {
  if (filter.type === "inRange") {
    return `${filter.type} ${filter.filter}_${filter.filterTo}`;
  } else {
    return `${filter.type} ${filter.filter}`;
  }
};

const handleMultipleFilter = ( columnName, { condition1, condition2, operator }) => {
  const filter1 = handleSimpleFilter(condition1);
  const filter2 = handleSimpleFilter(condition2);
  return `${columnName} ${filter1} ${operator} ${columnName} ${filter2}`;
};
