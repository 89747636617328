import { createUseFetch } from "../helpers/hooks";
import createItemFetcher from "../helpers/item";
import createConfigurationFetcher from "./configuration";
import { createUseItems } from "./fetchHooks";

const createConfigurationDuck = (
  configIdentifier,
  bodyParameterName,
  defaultValue = undefined
) => {
  return createConfigurationFetcher(
    configIdentifier,
    bodyParameterName,
    defaultValue
  );
};

export const conjunctionColumnsLayout = createConfigurationDuck(
  "conjunctions",
  "columns"
);
export const orbitColumnsLayout = createConfigurationDuck("orbits", "columns");
export const orbitConjunctionsColumnsLayout = createConfigurationDuck(
  "orbitconjunctions",
  "columns"
);
export const fleetConjunctionsColumnsLayout = createConfigurationDuck(
  "fleetconjunctions",
  "columns"
);
export const postOpmConjunctionsColumnsLayout = createConfigurationDuck(
  "postcamconjunctions",
  "columns"
);

export const plotsLayout = createConfigurationDuck("plots", "order", [
  "PLOTGEN",
  "RELATIVE_POSITION",
  "EVENTS_EVOLUTION",
  "ERROR_ELLIPSOID",
  "ERROR_EVOLUTION",
  "LAT_LONG",
  "OPS_SPC",
  "SPOC",
  "CAM",
]);

export const useSavedConjunctionColumns = createUseItems(
  conjunctionColumnsLayout,
  (state) => state.conjunctionColumnsLayout
);

export const useSavedOrbitColumns = createUseItems(
  orbitColumnsLayout,
  (state) => state.orbitColumnsLayout
);

export const useSavedOrbitConjunctionColumns = createUseItems(
  orbitConjunctionsColumnsLayout,
  (state) => state.orbitConjunctionsColumnsLayout
);

export const useSavedFleetConjunctionColumns = createUseItems(
  fleetConjunctionsColumnsLayout,
  (state) => state.fleetConjunctionsColumnsLayout
);

export const useSavedPostCamConjunctionColumns = createUseItems(
  postOpmConjunctionsColumnsLayout,
  (state) => state.postOpmConjunctionsColumnsLayout
);

export interface ConjHeadersConfig {}

export const conjHeadersUrlComputer = () => {
  return `/api/v1/config/conjunctionheaders`;
};

const availableConjHeadersId = () => {
  return `conjunction_available_headers`;
};

export const availableConjunctionHeaders = createItemFetcher<
  ConjHeadersConfig,
  any
>(
  conjHeadersUrlComputer,
  "AVAILABLE_CONJUNCTION_HEADERS",
  availableConjHeadersId,
  (a) => a,
  []
);
export const useAvailableconjunctionHeaders = createUseFetch<any>(
  availableConjunctionHeaders,
  (state) => state.availableConjunctionHeaders
);

export const usePlotsConfig = createUseItems(
  plotsLayout,
  (state) => state.plotsLayout
);
