import React from "react";
import { ColDef, GridOptions } from "ag-grid-community";
import { TABLE_ACTION } from "./configurations";
import { Table } from "./Table";

interface FiniteTableProps {
  rowData: any[];
  saveColumns: any;
  getFilter: () => void;
  setFilter: (param: any) => void;
  columnDefinitions: ColDef[];
  activeColumns: { colId: string; hide: boolean }[];
  source?: string;
  base: string;
  actions: TABLE_ACTION[];
  downloadData: (param: { id: string }) => void;
  rowClassRules?: any;
  adaptToContainerHeight?: boolean;
}

export const FiniteTable: React.FC<FiniteTableProps> = ({
  rowData,
  saveColumns,
  getFilter,
  setFilter,
  columnDefinitions,
  activeColumns,
  source,
  base,
  actions,
  downloadData,
  rowClassRules,
  adaptToContainerHeight,
}) => {

  // Initial variable that stores the columnApi to interact with AgGrid
  const [columnApi, setColumnApi] = React.useState({
    setColumnVisible: (name: string, value: boolean) => {
      console.error(
        "API not available. Tried to set column [" +
          name +
          "] visibility to [" +
          value +
          "]."
      );
    },
  });

  const onGridReady = (params) => {
    setColumnApi(params.columnApi);
    // Apply the column settings retrieved from the store's state or from the focusoc DB
    params.columnApi.applyColumnState({
      applyOrder: true,
      state: Object.values(activeColumns),
    });

    // set the state filter to the grid
    params.api.setFilterModel(getFilter());
  };

  const onFilterChanged = (params) => {
    // save the grid filter in the state
    setFilter(params.api.getFilterModel());
  };

  const gridOptions: GridOptions = {
    onGridReady,
    onFilterChanged,
  };

  return (
    <Table
      saveColumns={saveColumns}
      columnDefinitions={columnDefinitions}
      gridOptions={gridOptions}
      context={{
        source: source || undefined,
        base: base,
        actions: actions,
        downloadData: downloadData,
      }}
      rowData={rowData}
      rowClassRules={rowClassRules}
      columnApi={columnApi}
      activeColumns={activeColumns}
      adaptToContainerHeight={adaptToContainerHeight}
    />
  );
};
