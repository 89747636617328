import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useEffect } from "react";
import { OrbitConfig } from "./orbits";

/**
 * createUseOrbits creates a hook that manages the fetching and handling of the list of
 * all orbits
 * @param fetcher The fetcher for the orbits
 * @param statePath A function that given the state, returns the correct path to the relevant object
 */
export const createUseOrbits =
  (fetcher, statePath: (storeState: any) => any) => (config: OrbitConfig) => {
    const status = useSelector(
      (state) => fetcher.selectors.getStatus(statePath(state), { ...config }),
      shallowEqual
    );
    const error = useSelector(
      (state) => fetcher.selectors.getError(statePath(state), { ...config }),
      shallowEqual
    );
    const orbits = useSelector(
      (state) => fetcher.selectors.getOrbits(statePath(state), { ...config }),
      shallowEqual
    );
    const dispatch = useDispatch();

    // fetch item on mount or config change
    useEffect(() => {
      dispatch(fetcher.actions.fetchOrbits({ ...config }));
    }, [config, dispatch]);
    return { status, error, orbits };
  };
