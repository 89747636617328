import React, { useMemo } from "react";
import { Icon, Tab, Tabs } from "@blueprintjs/core";
import { ConjunctionInformationDetails } from "./ConjunctionInformationDetails";
import {
  useConjunctionCDM,
  useConjunctionDetail,
  useConjunctionOPM,
} from "../../services/conjunctions";
import {
  useAvailableconjunctionHeaders,
  usePlotsConfig,
} from "../../services/configuration";
import { StatusProxy } from "@focus/focusUI";
import { createFormattersObject } from "../../helpers/utils";
import { getCamPlot, getNonCamPlots, groupPlots } from "../grafos/helpers";
import { CAMPanel } from "../postcam/CAMPanel";
import "../../assets/stylesheets/internal_pages.css";
import { CDMContainer } from "../../containers/CDMContainer";
import { useHistory, useParams } from "react-router-dom";

interface ConjunctionInformationProps {
  pageTitle?: string;
}

export const ConjunctionInformation: React.FC<ConjunctionInformationProps> = ({
  pageTitle = "Conjunctions",
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const conjunctionConfig = useMemo(
    () => ({
      id,
      urlContext: "conjunctions",
    }),
    [id]
  );
  const availableConjHeaders = useAvailableconjunctionHeaders();
  const fetchedConjunction = useConjunctionDetail(conjunctionConfig);
  const fetchedCDM = useConjunctionCDM(conjunctionConfig.id);
  const fetchedOPM = useConjunctionOPM(conjunctionConfig.id);
  const conjunction = fetchedConjunction.value;
  const cdm = fetchedCDM.value;
  const opm = fetchedOPM.value;

  const getConjunctionPageTitle = (conj) => {
    let title = "Conjunction";
    if (conj?.conjunctionIdentifier) {
      title = `${conj?.conjunctionIdentifier?.value}`;
    }
    if (conj?.event?.eventId?.value) {
      title = `${title}  (Event ${conj?.event?.eventId?.value})`;
    }
    return title;
  };

  const formatters = createFormattersObject(availableConjHeaders.value);
  const [selectedTab, setSelectedTab] = React.useState(undefined);

  // fetch plots config from server
  const plotsConfig = usePlotsConfig();

  const parents =
    conjunction === undefined || conjunction?.event === undefined
      ? undefined
      : [conjunction, conjunction?.event];
  const plotGroups = groupPlots(parents, plotsConfig?.items, "conjunction");

  const nonCamPlots = getNonCamPlots(plotGroups);
  const camPlot = getCamPlot(plotGroups);

  return (
    <StatusProxy
      status={availableConjHeaders.status}
      error={availableConjHeaders.error}
    >
      <StatusProxy
        status={fetchedConjunction.status}
        error={fetchedConjunction.error}
      >
        <div className="foc-content">
          <div className="foc-page_title">
            <span className="foc-clickable" onClick={history.goBack}>
              {`${pageTitle}`}
            </span>
            <Icon
              className="foc-page_icon"
              icon="chevron-right"
              iconSize={18}
            />
            <span>{getConjunctionPageTitle(conjunction)}</span>
          </div>
          <Tabs
            animate={true}
            id="conjunction-detail-tabs"
            selectedTabId={selectedTab}
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
          >
            <Tab
              id="details"
              title="Details"
              key="details"
              panel={
                <ConjunctionInformationDetails
                  conjunction={conjunction}
                  formatters={formatters}
                />
              }
            />
            {nonCamPlots.length > 0 ? (
              <Tab
                id="plots"
                title="Plots"
                key="plots"
                panel={
                  <div className="foc-tab-content center-aligned">
                    {nonCamPlots}
                  </div>
                }
              />
            ) : null}
            {camPlot || opm?.id ? (
              <Tab
                id="cam"
                title="CAM"
                key="cam"
                panel={
                  <div className="foc-tab-content center-aligned">
                    <CAMPanel
                      camPlot={camPlot}
                      availableConjHeaders={availableConjHeaders}
                      opmId={opm?.id}
                    />
                  </div>
                }
              />
            ) : null}
            {cdm?.id ? (
              <Tab id="cdm" title="CDM" panel={<CDMContainer cdmId={cdm.id.toString()} />} />
            ) : null}
          </Tabs>
        </div>
      </StatusProxy>
    </StatusProxy>
  );
};
