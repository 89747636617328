import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { saveAs } from "file-saver/FileSaver";
import * as actions from "./actions";
import { Actions } from "./actions";
import { actionTypes } from "./actionTypes";
import { DownloadConfig } from "./interfaces";
import {
  downloadOrbit,
  downloadZipOrbit,
  showUploadErrorPopup,
  showUploadOkPopup,
  uploadOrbit,
} from "./middleware";
import { createNotificationsEpicForAction } from "./notification";

export const uploadOrbitUrlBuilder = (operator) => {
  return `/api/v1/inputs/${operator}/orbits`;
};

export const downloadConjunctionFilesUrl = ({ id }: DownloadConfig) =>
  `/api/v1/conjunctions/${id}/files`;

export const downloadOrbitFilesUrl = ({ id }: DownloadConfig) =>
  `/api/v1/orbits/${id}/files`;

const initialState = {};

function reducer(state = initialState, actionFile: Actions) {
  const action: any = actionFile;
  const fileEntries = action?.payload?.files ? action?.payload?.files : [];

  const updatedState = {};
  switch (action.type) {
    case actionTypes.UPLOAD_ERROR:
      fileEntries.forEach((fe) => {
        updatedState[fe.id] = {
          filename: fe.file.name,
          operator: action.payload.operator,
          status: ItemStatus.ERROR,
          errorMessage: action.payload,
        };
      });
      return { ...state, ...updatedState };
    case actionTypes.UPLOAD_PENDING:
      fileEntries.forEach((fe) => {
        updatedState[fe.id] = {
          filename: fe.file.name,
          operator: action.payload.operator,
          status: ItemStatus.PENDING,
          errorMessage: "",
        };
      });
      return { ...state, ...updatedState };
    case actionTypes.UPLOAD_FINISH:
      fileEntries.forEach((fe) => {
        updatedState[fe.id] = {
          filename: fe.file.name,
          operator: action.payload.operator,
          status: ItemStatus.READY,
          errorMessage: "",
        };
      });
      return { ...state, ...updatedState };
    case actionTypes.UPLOAD_DELETE:
      return initialState;
    default:
      return state;
  }
}

export const createUploadOrbit = () => {
  const middleware = [
    uploadOrbit(uploadOrbitUrlBuilder),
    downloadZipOrbit(downloadConjunctionFilesUrl, saveAs),
    downloadOrbit(downloadOrbitFilesUrl, saveAs),
    createNotificationsEpicForAction(),
    showUploadOkPopup(),
    showUploadErrorPopup(),
  ];
  const selectors = {};
  return { actions, reducer, middleware, selectors };
};
