import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../../assets/stylesheets/ag-grid_styles.css";

import "@blueprintjs/core/lib/css/blueprint.css";

import { ConjunctionsDatabaseTable } from "./ConjunctionsDatabaseTable";

interface ConjunctionsDatabaseProps {
  match: {
    params: {
      page: number;
    };
  };
  history: any;
  location: any;
  downloadZipOrbits: (param: { id: string }) => void;
  saveConjunctionColumns: (param: object) => void;
  saveConjunctionsFilter: (param: object) => void;
  conjunctionsFilter: object;
}

export const ConjunctionsDatabase: React.FunctionComponent<
  ConjunctionsDatabaseProps
> = ({
  match,
  history,
  location,
  downloadZipOrbits,
  saveConjunctionColumns,
  saveConjunctionsFilter,
  conjunctionsFilter,
}) => {
  return (
    <div className="foc-content">
      <div className="foc-page_title">
        <span>Conjunctions database</span>
      </div>
      <div className="foc-table-container">
        <ConjunctionsDatabaseTable
          history={history}
          location={location}
          page={match.params.page}
          downloadZipOrbits={downloadZipOrbits}
          saveConjunctionColumns={saveConjunctionColumns}
          saveConjunctionsFilter={saveConjunctionsFilter}
          conjunctionsFilter={conjunctionsFilter}
        />
      </div>
    </div>
  );
};
