import { Toaster } from "@blueprintjs/core";
import { NotificationContext, provideContext } from "@focus/focusUI";
import { Duck } from "@focus/interfaces/lib/common/Duck";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { createToaster } from "@focus/services";
import createReducer from "@focus/services/lib/reducers";
import "@focus/services/lib/reduxObservableConfig";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createMiddleware from "./middleware";
import {
  availableConjunctionHeaders,
  conjunctionColumnsLayout,
  orbitColumnsLayout,
  orbitConjunctionsColumnsLayout,
  fleetConjunctionsColumnsLayout,
  postOpmConjunctionsColumnsLayout,
  plotsLayout,
} from "./services/configuration";
import { conjCDMDuck, conjOPMDuck, conjunctionDetail, conjunctions, fleetConjunctions } from "./services/conjunctions/index";
import { createUploadOrbit } from "./services/downloaduploadOrbits/index";
import { grafosConfig, grafosDat, grafosJson } from "./services/grafos/index";
import { cdmDuck } from "./services/cdm";
import { orbitDetail, orbits, orbitConjunctions } from "./services/orbits/index";
import { plots } from "./services/plots/index";
import { opm, opmConjunctions } from "./services/postcam/index";
import createServerConfiguration from "./services/serverConfig/configuration";

const uploadedOrbits = createUploadOrbit();
const toaster = createToaster(Toaster.create() as any);
const serverConfig = createServerConfiguration();

const ducks = {
  conjunctions,
  opm,
  opmConjunctions,
  conjunctionDetail,
  grafosConfig,
  grafosDat,
  grafosJson,
  plots,
  orbits,
  orbitDetail,
  orbitConjunctions,
  uploadedOrbits,
  toaster,
  availableConjunctionHeaders,
  conjunctionColumnsLayout,
  orbitColumnsLayout,
  orbitConjunctionsColumnsLayout,
  fleetConjunctionsColumnsLayout,
  postOpmConjunctionsColumnsLayout,
  plotsLayout,
  serverConfig,
  fleetConjunctions,
  cdm: cdmDuck,
  conjunctionCDM: conjCDMDuck,
  conjunctionOPM: conjOPMDuck,
};

// allow redux devtools if available in browser
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/**
 * duck configuration object
 */
type Ducks = {
  [key: string]: Duck;
};

function buildStore(ducks: Ducks, stomp?) {
  const initialState = undefined;
  type StoreConjunctions = {
    conjunctions: {
      items: any[];
      status: ItemStatus;
      error: string;
      filter: object;
      sort: object;
    };
    fleetConjunctions: {
      items: any[];
      status: ItemStatus;
      error: string;
      filter: object;
      sort: object;
    };
    conjunctionDetail: {
      status: ItemStatus;
      errorMessage: string;
      content: any;
    };
    grafosConfig: any[];
    grafosDat: any[];
    grafosJson: any[];
    plots: any[];
    orbits: {
      items: any[];
      status: ItemStatus;
      error: string;
      filter: object;
      sort: object;
    };
    orbitDetail: {
      status: ItemStatus;
      errorMessage: string;
      content: any;
    };
    orbitConjunctions: {
      items: any[];
      status: ItemStatus;
      error: string;
      filter: object;
      sort: object;
    };
    availableConjunctionHeaders: {
      items: any[];
      status: ItemStatus;
      error: string;
    };
    opm: {
      items: any[];
      status: ItemStatus;
      error: string;
    };
    opmConjunctions: {
      items: any[];
      status: ItemStatus;
      error: string;
    };
    uploadedOrbits: any;
    conjunctionsNumberPages: number;
    plotsLayout: {
      items: any[];
      status: ItemStatus;
      error: string;
    };
    conjunctionColumnsLayout: {
      items: string[];
      status: ItemStatus;
      errorMessage: string;
    };
    fleetConjunctionsColumnsLayout: {
      items: string[];
      status: ItemStatus;
      errorMessage: string;
    };
    postOpmConjunctionsColumnsLayout: {
      items: string[];
      status: ItemStatus;
      errorMessage: string;
    };
    orbitColumnsLayout: {
      items: string[];
      status: ItemStatus;
      errorMessage: string;
    };
    orbitConjunctionsColumnsLayout: {
      items: string[];
      status: ItemStatus;
      errorMessage: string;
    };
    serverConfig: any; // TODO: cambiar tipo
  };
  const reducers = createReducer(ducks);
  const middleware = composeEnhancers(
    applyMiddleware(createMiddleware(ducks, stomp))
  );
  const store: Store<StoreConjunctions> = createStore(
    reducers,
    initialState,
    middleware
  );
  return store;
}

export const store = buildStore(ducks);

const contextConfig = [{ context: NotificationContext, value: toaster }];

export const withContext = provideContext(contextConfig);
