export enum actionTypes {
    UPLOAD = "UPLOAD/START",
    UPLOAD_PENDING = "UPLOAD/PENDING",
    UPLOAD_ERROR = "UPLOAD/ERROR",
    UPLOAD_FINISH = "UPLOAD/FINISH",
    UPLOAD_DELETE = "UPLOAD/DELETE",
    DOWNLOAD_ZIP = "DOWNLOAD_ZIP/START",
    DOWNLOAD_ZIP_REFRESH = "DOWNLOAD_ZIP/REFRESH",
    DOWNLOAD_ZIP_PENDING = "DOWNLOAD_ZIP/PENDING",
    DOWNLOAD_ZIP_ERROR = "DOWNLOAD_ZIP/ERROR",
    DOWNLOAD_ZIP_FINISH = "DOWNLOAD_ZIP/FINISH",
    DOWNLOAD = "DOWNLOAD/START",
    DOWNLOAD_REFRESH = "DOWNLOAD/REFRESH",
    DOWNLOAD_PENDING = "DOWNLOAD/PENDING",
    DOWNLOAD_ERROR = "DOWNLOAD/ERROR",
    DOWNLOAD_FINISH = "DOWNLOAD/FINISH",
}