import { readFormat } from "../services/helpers/headers";

/**
 * Searchs for a sub-element within the given element, by going through an array of strings that indicate the path to the sub-element
 * @param element The element where the descendant is going to be searched for
 * @param descendantPathArray Array of strings defining the location of the descendant within the given element
 */
export const getElementDescendant = (
  element,
  descendantPathArray: string[]
) => {
  if (element && descendantPathArray.length > 0) {
    return getElementDescendant(
      element[descendantPathArray.shift()],
      descendantPathArray
    );
  }
  return element;
};

export const applyFormat = (element, paramName, formatters) => {
  const formatter = formatters ? formatters[paramName] : undefined;
  const attributeRoute = paramName.split(".");

  if (paramName.endsWith(".value")) {
    attributeRoute.pop();
    const valueObject = getElementDescendant(element, attributeRoute);
    const formattedValue = formatter
      ? formatter(valueObject.value)
      : valueObject.value;
    return { ...valueObject, value: formattedValue };
  } else {
    const valueObject = getElementDescendant(element, attributeRoute);
    const formattedValue = formatter ? formatter(valueObject.value) : valueObject.value;
    return formattedValue;
  }
};

export const createFormattersObject = (fieldsFormat) => {
  const formatters = {};
  fieldsFormat?.forEach((f) => {
    if (f.format !== undefined) {
      formatters[f.field] = readFormat(f.format);
    }
  });
  return formatters;
};
