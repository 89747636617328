import { createUseItems } from "../configuration/fetchHooks";
import createConjunctionsFetcher from "../conjunctions/conjunctions";
import { checkResponse } from "../helpers";
import { createDetailFetcher } from "../helpers/fetchers";
import { parseFilter } from "../helpers/filtering";
import { createUseDetail } from "../helpers/hooks";
import { parseSort } from "../helpers/sorting";
import KeycloakManager from "../keycloak";
import { createUseOrbits } from "./fetchHooks";
import createOrbitsFetcher from "./orbits";

// Custom service in charge of handling specific orbits
const orbitDetailFetcher = () => createDetailFetcher("ORBIT");
export const orbitDetail = orbitDetailFetcher();
export const useOrbitDetail = createUseDetail(
  orbitDetail,
  (state) => state.orbitDetail
);

// Custom service and associated hook in charge of handling the list of orbits
const createOrbits = () => createOrbitsFetcher();
export const orbits = createOrbits();
export const useOrbits = createUseOrbits(orbits, (state) => state.orbits);

const _getOrbitsPage = async ({ size, page, filter, sort }) => {
  const headers = { Authorization: `Bearer ${KeycloakManager.getToken()}` };
  const parsedFilter = parseFilter(filter);
  const parsedSort = parseSort(sort);

  const url = `/api/v1/orbits?pageSize=${size}&page=${page}${parsedFilter}${parsedSort}`;
  return await fetch(url, { headers });
};

export const getOrbitsPage = async ({ size, page, filter, sort }) => {
  const orbitsPromise = (
    await _getOrbitsPage({ size, page, filter, sort })
  ).json();
  const response = await orbitsPromise;
  return checkResponse(response, "orbits page");
};

const _getOrbitRows = async ({ filter }) => {
  const headers = { Authorization: `Bearer ${KeycloakManager.getToken()}` };
  let parsedFilter = parseFilter(filter);
  if (parsedFilter) {
    parsedFilter = parsedFilter.slice(1);
  } 
  const url = `/api/v1/orbits/count?${parsedFilter}`;
  return await fetch(url, { headers });
};

export const getOrbitRows = async ({ filter }) => {
  const orbitRowsPromise = (await _getOrbitRows({ filter })).json();
  const response = await orbitRowsPromise;
  return checkResponse(response, "orbit rows");
};

export interface Params {
  key: string;
  value: string;
}

export interface OrbitConjunctionsConfig {
  id: number;
  params?: Params[];
}

const orbitConjunctionsUrlComputer = (config: OrbitConjunctionsConfig) => {
  let queryParams = "";
  if (config?.params !== undefined) {
    config.params.forEach((p) => (queryParams += `&${p.key}=${p.value}`));
    queryParams = `?${queryParams.substring(1)}`;
  }
  return `/api/v1/orbits/${config?.id}/conjunctions${queryParams}`;
};

export const orbitConjunctions = createConjunctionsFetcher({
  computeURL: orbitConjunctionsUrlComputer,
  storeId: "ORBIT_CONJUNCTIONS",
});
export const useOrbitConjunctions = createUseItems(
  orbitConjunctions,
  (state) => state.orbitConjunctions
);
