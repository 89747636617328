import * as React from "react";
import { FleetTable } from "./FleetTable";

interface FleetStatusProps {
  downloadFleetZipOrbits: (param: { id: string }) => void;
  saveFleetConjunctionColumns: () => void;
  resetFleetConjunctionsInStore: () => void;
  setStateFleetConjunctionsFilter: (param: { filter: object }) => void;
  getStateFleetConjunctionsFilter: () => { filter: object };
}

export const FleetStatus: React.FunctionComponent<FleetStatusProps> = ({
  downloadFleetZipOrbits,
  saveFleetConjunctionColumns,
  setStateFleetConjunctionsFilter,
  getStateFleetConjunctionsFilter,
}) => {
  const [conjunctionsNumber, setConjunctionsNumber] = React.useState(undefined);
  const setNumberOfConjunctionsInTitle = (num: number) => {
    setConjunctionsNumber(num);
  };

  return (
    <div className="foc-content">
      <div className="foc-page_title">
        <span>
          {conjunctionsNumber
            ? `Fleet status (${conjunctionsNumber} conjunctions)`
            : `Fleet status`}
        </span>
      </div>
      <div className="foc-table-container">
        <FleetTable
          downloadZipOrbits={downloadFleetZipOrbits}
          saveFleetConjunctionColumns={saveFleetConjunctionColumns}
          setStateFleetConjunctionsFilter={setStateFleetConjunctionsFilter}
          getStateFleetConjunctionsFilter={getStateFleetConjunctionsFilter}
          setNumberOfConjunctionsInTitle={setNumberOfConjunctionsInTitle}
        />
      </div>
    </div>
  );
};
