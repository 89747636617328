import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './assets/stylesheets/index.css';
import * as serviceWorker from './serviceWorker';
import KeycloakManager from "./services/keycloak";
import { store } from './store';

/**
 * renderApp is the main app launcher. It starts React, rendering the App component, which contains the rest
 * of the application. As focusoc uses keycloak, this function is passed to the Keycloak initialisation function,
 * which will call it aftr authentification.
 */
const renderApp = () => ReactDOM.render(<App {...{store}} />, document.getElementById('root'));
KeycloakManager.initKeycloak(renderApp);

serviceWorker.unregister();