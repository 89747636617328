import { StatusProxy } from "@focus/focusUI";
import React from "react";
import { useOpm } from "../../services/postcam";
import { XMLViewer } from "../XMLViewer";

import "../../assets/stylesheets/internal_pages.css";

interface XMLContentProps {
  opmId: number;
}

export const XMLContent: React.FC<XMLContentProps> = ({ opmId }) => {
  const ompIdMemo = React.useMemo(() => ({ id: opmId }), [opmId]);
  const opm = useOpm(ompIdMemo);
  return (
    <StatusProxy status={opm.status} error={opm.error}>
      <div className="xml-container">
        <div className="foc-page_subtitle">{opm.value?.filename}</div>
        <XMLViewer content={opm.value?.fileContent}/>
      </div>
    </StatusProxy>
  );
};
