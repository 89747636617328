import { Observable } from "rxjs";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";

export type urlComputer<T> = (config: T) => string;
export type ItemFetcher = ReturnType<typeof createItemFetcher>;
/**
 * creates a duck for a collection of downloadable items
 *
 * @param urlComputer a function that provides the url for the fetch base on a config
 * @param nameSpace namespace corresponding to the item
 * @param computeItemId id computing function based on the config
 */
export default function createItemFetcher<ConfigPanel, Item extends Object>(
  urlComputer: urlComputer<ConfigPanel>,
  nameSpace: string,
  computeItemId: (any) => string,
  bodyParser: (any) => Item = (a) => a, 
  pendingContent: any = {}
) {
  // define constants
  const ActionKeys = {
    SET_ITEM: `${nameSpace}/SET` as "ITEM/FETCH",
    DELETE_ITEM: `${nameSpace}/DELETE` as "ITEM/DELETE",
    UPDATE_ITEM: `${nameSpace}/UPDATE` as "ITEM/UPDATE",
    FETCH_ITEM: `${nameSpace}/FETCH_START` as "ITEM/FETCH_START",
    FETCH_ITEM_ERROR: `${nameSpace}/FETCH_ERROR` as "ITEM/FETCH_ERROR",
    FETCH_ITEM_PENDING: `${nameSpace}/FETCH_PENDING` as "ITEM/FETCH_PENDING",
  };

  // ACTIONS
  /**
   * Command to fetch the item
   * @param payload
   */
  const fetchItem = (payload: ConfigPanel) => ({
    type: ActionKeys.FETCH_ITEM as typeof ActionKeys.FETCH_ITEM,
    payload,
  });
  type FetchItem = ReturnType<typeof fetchItem>;

  /**
   * Command to delete the item
   * @param payload
   */
  const deleteItem = (config: ConfigPanel) => {
    const id = computeItemId(config);
    return {
      type: ActionKeys.DELETE_ITEM as typeof ActionKeys.DELETE_ITEM,
      id,
    };
  };
  type DeleteItem = ReturnType<typeof deleteItem>;

  /**
   * Command to update the item
   * @param payload
   */
  const updateItem = (config: ConfigPanel, payload: Partial<Item>) => {
    const id = computeItemId(config);
    return {
      type: ActionKeys.UPDATE_ITEM as typeof ActionKeys.UPDATE_ITEM,
      id,
      payload,
    };
  };
  type UpdateItem = ReturnType<typeof updateItem>;

  /**
   * set the fetch error for the item
   * @param config
   * @param payload
   */
  const setItemFetchError = (config: ConfigPanel, payload: string) => {
    const id = computeItemId(config);
    return {
      type: ActionKeys.FETCH_ITEM_ERROR as typeof ActionKeys.FETCH_ITEM_ERROR,
      id,
      payload,
    };
  };
  type SetItemFetchError = ReturnType<typeof setItemFetchError>;

  /**
   * set the item content
   * @param config
   * @param payload
   */
  const setItem = (config: ConfigPanel, payload: Item) => {
    const id = computeItemId(config);
    return {
      type: ActionKeys.SET_ITEM as typeof ActionKeys.SET_ITEM,
      id,
      payload,
    };
  };
  type SetItem = ReturnType<typeof setItem>;

  /**
   * set the item as pending download
   * @param config
   */
  const setItemFetchPending = (config: ConfigPanel) => {
    const id = computeItemId(config);
    return {
      type: ActionKeys.FETCH_ITEM_PENDING as typeof ActionKeys.FETCH_ITEM_PENDING,
      id,
    };
  };
  type SetItemFetchPending = ReturnType<typeof setItemFetchPending>;

  // actions to be exported
  const actions = {
    fetchItem,
    setItemFetchError,
    setItemFetchPending,
    setItem,
    deleteItem,
    updateItem,
  };

  type Actions =
    | SetItemFetchPending
    | FetchItem
    | SetItemFetchError
    | SetItem
    | DeleteItem
    | UpdateItem;
  // REDUCER
  type ItemHolder = {
    readonly status: ItemStatus;
    readonly errorMessage: string;
    readonly content: Item;
  };
  type State = {
    [id: string]: ItemHolder;
  };
  const initialState: State = {};

  // Reducer function
  function reducer(state: State = initialState, action: Actions) {
    let currentItem;
    switch (action.type) {
      case ActionKeys.SET_ITEM:
        currentItem = state[action.id] || {};
        return Object.assign({}, state, {
          [action.id]: {
            ...currentItem,
            status: ItemStatus.READY,
            errorMessage: "",
            content: action.payload,
          },
        });
      case ActionKeys.DELETE_ITEM:
        const currentState = Object.assign({}, state);
        delete currentState[action.id];
        return currentState;
      case ActionKeys.UPDATE_ITEM:
        currentItem = state[action.id] || {};
        return Object.assign({}, state, {
          [action.id]: {
            ...currentItem,
            status: ItemStatus.READY,
            errorMessage: "",
            content: { ...currentItem.content, ...(action.payload as Object) },
          },
        });
      case ActionKeys.FETCH_ITEM_ERROR:
        currentItem = state[action.id] || {};
        return Object.assign({}, state, {
          [action.id]: {
            ...currentItem,
            status: ItemStatus.ERROR,
            errorMessage: action.payload,
            content: {},
          },
        });
      case ActionKeys.FETCH_ITEM_PENDING:
        currentItem = state[action.id] || {};
        return Object.assign({}, state, {
          [action.id]: {
            ...currentItem,
            status: ItemStatus.PENDING,
            errorMessage: "",
            content: pendingContent,
          },
        });
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * Get the item content
   * @param  {State} state
   * @param  {ConfigPanel} config
   */
  function getItem(state: State, config: ConfigPanel) {
    const id = computeItemId(config);
    return state[id] && state[id].content;
  }

  /**
   * check if the item is loaded, returns false while loading
   *
   * @param  {State} state
   * @param  {ConfigPanel} config
   */
  function isItemLoaded(state: State, config: ConfigPanel) {
    const id = computeItemId(config);
    const item = state[id] || { status: ItemStatus.PENDING };
    return item.status !== ItemStatus.PENDING;
  }

  /**
   * check if the item is has been loaded with errors
   *
   * @param  {State} state
   * @param  {ConfigPanel} config
   */
  function hasItemError(state: State, config: ConfigPanel) {
    const id = computeItemId(config);
    const item = state[id] || { status: null };
    return item.status === ItemStatus.ERROR;
  }

  /**
   * Get the error message
   *
   * @param  {State} state
   * @param  {ConfigPanel} config
   */
  function getItemError(state: State, config: ConfigPanel) {
    const id = computeItemId(config);
    const item = state[id] || { errorMessage: null };
    return item.errorMessage;
  }
  /**
   * Get the Action status
   * @param  {State} state
   * @param  {ActionConfig} config
   */
  function getStatus(state: State, config: ConfigPanel) {
    const id = computeItemId(config);
    const status = state[id] && state[id].status;
    return status || ItemStatus.PENDING;
  }

  const selectors = {
    getItem,
    isItemLoaded,
    hasItemError,
    getItemError,
    getStatus,
  };
  // MIDDLEWARE

  /**
   * Epic creator for the fetch
   *
   * @param  {} action$ Actions stream
   * @param  {} _ the store , not used
   * @param  {} {ajax} The ajax service
   */
  const fetchItemEpic = (action$, _, { ajax }: { ajax; stomp? }) =>
    action$.ofType(ActionKeys.FETCH_ITEM).mergeMap((action) =>
      ajax
        .getJSON(urlComputer(action.payload))
        .map((response) => setItem(action.payload, bodyParser(response)))
        .catch((error: FocusAjaxError, source) => {
          return Observable.of(
            setItemFetchError(action.payload, getErrorMessage(error))
          );
        })
        .startWith(setItemFetchPending(action.payload))
    );

  const middleware = [fetchItemEpic];
  return { actions, reducer, middleware, selectors, ActionKeys };
}
