import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AppsMenu } from "./AppsMenu";

interface SideBarProps {
  collapsed: boolean;
}

const _SideBar: React.FC<SideBarProps & RouteComponentProps> = ({
  collapsed,
}) => {
  return <AppsMenu collapsed={collapsed} />;
};

export const SideBar = withRouter(_SideBar);
