import { Duck } from "@focus/interfaces/lib/common/Duck";
import { ItemStatus } from "@focus/interfaces/lib/common/itemFetchStatus";
import { FocusAjaxError } from "@focus/interfaces/lib/common/serverError";
import { getErrorMessage } from "@focus/services/lib/helpers/errorParser";
import { Observable } from "rxjs";
import KeycloakManager from "../keycloak";

export default function createConfigurationFetcher(
  configIdentifier: string,
  bodyParameterName: string,
  defaultValue: any
): Duck {
  function computeURL(item: string) {
    return `/api/v1/config/${item}`;
  }

  // define constants
  const SET = `${configIdentifier.toUpperCase()}_CONFIG/SET`;
  const SET_DEFAULT = `${configIdentifier.toUpperCase()}_CONFIG/SET_DEFAULT`;
  const DELETE = `${configIdentifier.toUpperCase()}_CONFIG/DELETE`;
  const FETCH = `${configIdentifier.toUpperCase()}_CONFIG/FETCH_START`;
  const FETCH_ERROR = `${configIdentifier.toUpperCase()}_CONFIG/FETCH_ERROR`;
  const FETCH_PENDING = `${configIdentifier.toUpperCase()}_CONFIG/FETCH_PENDING`;
  const SAVE = `${configIdentifier.toUpperCase()}_CONFIG/SAVE`;
  const SAVE_PENDING = `${configIdentifier.toUpperCase()}_CONFIG/SAVE_PENDING`;
  const SAVE_OK = `${configIdentifier.toUpperCase()}_CONFIG/SAVE_OK`;
  const SAVE_ERROR = `${configIdentifier.toUpperCase()}_CONFIG/SAVE_SAVE_ERROR`;

  // ACTIONS
  function fetchItems() {
    return { type: FETCH };
  }
  function setItems(payload) {
    return { type: SET, payload };
  }
  function setItemsPending() {
    return { type: FETCH_PENDING };
  }
  function deleteItems() {
    return { type: DELETE };
  }
  function setError(payload: string) {
    return { type: FETCH_ERROR, payload };
  }
  function setDefault() {
    return { type: SET_DEFAULT };
  }
  function saveItems(payload) {
    return { type: SAVE, payload };
  }
  function saveItemsPending() {
    return { type: SAVE_PENDING };
  }
  function saveItemsOk(payload) {
    return { type: SAVE_OK, payload };
  }
  function saveItemsError(payload: string) {
    return { type: SAVE_ERROR, payload };
  }

  // REDUCER
  // default state
  const initialState = {
    items: [],
    status: ItemStatus.PENDING,
    errorMessage: "",
    save: undefined,
  };

  /**
   * Reducer to handle the configuration
   *
   * @param  {Object} state={}
   * @param  {Object} action
   */
  function reducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PENDING:
        return Object.assign({}, state, {
          items: [],
          status: ItemStatus.PENDING,
          errorMessage: "",
        });
      case FETCH_ERROR:
        return Object.assign({}, state, {
          items: [],
          status: ItemStatus.ERROR,
          errorMessage: action.payload,
        });
      case SET:
        return Object.assign({}, state, {
          items: action.payload,
          status: ItemStatus.READY,
          errorMessage: "",
          save: undefined,
        });
      case SET_DEFAULT:
        return Object.assign({}, state, {
          items: defaultValue,
          status: ItemStatus.READY,
          errorMessage: "",
        });
      case DELETE:
        return { ...initialState };
      case SAVE_PENDING:
        return Object.assign({}, state, {
          save: ItemStatus.PENDING,
        });
      case SAVE_OK:
        return Object.assign({}, state, {
          items: action.payload,
          status: ItemStatus.READY,
          errorMessage: "",
          save: ItemStatus.READY,
        });
      case SAVE_ERROR:
        return Object.assign({}, state, {
          save: action.payload,
        });
      default:
        return state;
    }
  }

  // SELECTORS
  /**
   * @param  {Object} state
   */
  function getItems(state) {
    return state.items;
  }

  /**
   * @param  {Object} state
   */
  function getStatus(state) {
    return state.status;
  }
  /**
   * Get the error message if any
   * @param  {} state the state object
   */
  function getError(state) {
    return state.errorMessage;
  }

  /**
   * Epic creator for the fetch
   *
   * @param  {} action$ Actions stream
   * @param  {} _
   * @param  {} {ajax} The ajax service
   */
  const fetchItemsEpic = (action$, _, { ajax }) =>
    action$.ofType(FETCH).mergeMap((action) =>
      ajax
        .getJSON(computeURL(configIdentifier))
        .map((response) => {
          if (bodyParameterName) {
            return setItems(response[bodyParameterName]);
          } else {
            return setItems(response);
          }
        })
        .catch((error: FocusAjaxError, source) => {
          return error.status === 404
            ? Observable.of(setDefault())
            : Observable.of(setError(getErrorMessage(error)));
        })
        .startWith(setItemsPending())
    );

  const saveItemsEpic = (action$, _, { ajax }) =>
    action$.ofType(SAVE).mergeMap((action) => {
      const body = {};
      body[bodyParameterName] = action.payload;
      return ajax({
        url: `/api/v1/config/${configIdentifier}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${KeycloakManager.getToken()}`,
        },
      })
        .map((response) => {
          // Once the column settings are stored in the backend, include them in the store
          return saveItemsOk(action.payload);
        })
        .catch((error: FocusAjaxError) =>
          Observable.of(saveItemsError(getErrorMessage(error)))
        )
        .startWith(saveItemsPending());
    });

  const actionTypes = {
    SET,
    DELETE,
    FETCH,
    FETCH_ERROR,
    FETCH_PENDING,
    SAVE,
    SAVE_PENDING,
    SAVE_ERROR,
  };

  const actions = {
    fetchItems,
    setItems,
    deleteItems,
    setError,
    saveItems,
  };

  const selectors = {
    getItems,
    getStatus,
    getError,
  };

  const middleware = [fetchItemsEpic, saveItemsEpic];
  return { actions, reducer, middleware, selectors, actionTypes };
}
