import * as React from "react";
import "./Layout.scss";

interface Props {
  Header;
  Menu;
  Content;
  store;
  logo: string;
}

interface State {
  collapsed: boolean;
}

export default class Layout extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
  }

  toggleCollapse = () => {
    this.setState(state => ({ collapsed: !state.collapsed }));
  };

  render() {
    const {
      Header = () => <div />,
      Menu = () => <div />,
      Content = () => <div />,
      store,
      logo
    } = this.props;
    return (
      <div className="layout-main">
        <div className="layout-vertical">
          <div className="layout-header grey">
            <Header
              key="header"
              logo={logo}
              collapsed={this.state.collapsed}
              onChange={this.toggleCollapse}
            />
          </div>
          <div className="layout-horizontal layout-lower">
            <div className="layout-lateral bp4-dark">
              <Menu key="menu" collapsed={this.state.collapsed} />
            </div>
            <div className="layout-content">
              <Content store={store} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
