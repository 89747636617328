import * as React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../../assets/stylesheets/ag-grid_styles.css";
import "../../assets/stylesheets/internal_pages.css"

import { CONJ_DEFAULT_VISIBLE_COLUMNS } from "../../services/conjunctions/Headers";
import { TABLE_ACTION } from "../table/configurations";
import { FiniteTable } from "../table/FiniteTable";

import {
  useSavedOrbitConjunctionColumns,
  useAvailableconjunctionHeaders,
} from "../../services/configuration";

import { StatusProxy } from "@focus/focusUI";
import {
  getColumnDefinitions,
  getVisibleColumns,
} from "../../services/helpers/headers";
import { useOrbitConjunctions } from "../../services/orbits";

interface OrbitConjunctionsTableProps {
  id: string;
  page?: number;
  downloadZipOrbits: (param: { id: string }) => void;
  saveOrbitConjunctionsInStore: () => void;
  saveOrbitConjunctionColumns: () => void;
  resetOrbitConjunctionsInStore: () => void;
  setStateOrbitConjunctionsSort: (param: { sort: object[] }) => void;
  setStateOrbitConjunctionsFilter: (param: { filter: object }) => void;
  getStateOrbitConjunctionsFilter: () => { filter: object };
}

export const OrbitConjunctionsTable: React.FC<OrbitConjunctionsTableProps> = ({
  id,
  downloadZipOrbits,
  saveOrbitConjunctionColumns,
  setStateOrbitConjunctionsFilter,
  getStateOrbitConjunctionsFilter,
}) => {
  // Request the user active columns to the server's DB
  const headersConfig = useAvailableconjunctionHeaders();
  const columnsConfig = useSavedOrbitConjunctionColumns();
  const columnDefinitions = getColumnDefinitions(headersConfig.value, true);

  // If no headers are available in the DB, load the default active columns
  const visibleColumns = getVisibleColumns(
    columnsConfig.items,
    CONJ_DEFAULT_VISIBLE_COLUMNS,
    columnDefinitions
  );

  const conjunctions = useOrbitConjunctions({
    id: parseInt(id)
  });

  return (
    <StatusProxy status={headersConfig.status} error={headersConfig.error}>
      <StatusProxy status={columnsConfig.status} error={columnsConfig.error}>
        <StatusProxy status={conjunctions.status} error={conjunctions.error}>
          <div className="element-conjunctions">
            <div className="foc-page_subtitle">
              <span>Orbit conjunctions</span>
            </div>
            <FiniteTable
              rowData={conjunctions.items}
              saveColumns={saveOrbitConjunctionColumns}
              columnDefinitions={columnDefinitions}
              setFilter={setStateOrbitConjunctionsFilter}
              getFilter={getStateOrbitConjunctionsFilter}
              activeColumns={visibleColumns}
              base={"conjunctions"}
              actions={[
                TABLE_ACTION.DOWNLOAD,
                TABLE_ACTION.INFO,
                TABLE_ACTION.PLOTS,
              ]}
              downloadData={downloadZipOrbits}
              rowClassRules={{
                "row-alert": (params) =>
                  params.api.getValue("riskLevel.value", params.node) ===
                  "ALERT",
                "row-warning": (params) =>
                  params.api.getValue("riskLevel.value", params.node) ===
                  "WARNING",
              }}
              adaptToContainerHeight={false}
            />
          </div>
        </StatusProxy>
      </StatusProxy>
    </StatusProxy>
  );
};
