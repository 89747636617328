import { connect } from "react-redux";
import { FleetStatus } from "../components/fleetStatus/FleetStatus";
import { fleetConjunctionsColumnsLayout } from "../services/configuration";
import { fleetConjunctions } from "../services/conjunctions";
import { downloadFileZip } from "../services/downloaduploadOrbits/actions";

const fleetConjunctionActions = fleetConjunctions.actions;
const fleetConjunctionSelectors = fleetConjunctions.selectors;
const fleetConjunctionsLayoutActions = fleetConjunctionsColumnsLayout.actions;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  getStateFleetConjunctionsFilter: () => {
    return fleetConjunctionSelectors.getFilter(state.fleetConjunctions);
  },
});

const mapDispatchToProps = (dispatch) => ({
  downloadFleetZipOrbits: (payload) => {
    dispatch(downloadFileZip(payload));
  },
  setStateFleetConjunctionsFilter: (filter) => {
    return dispatch(fleetConjunctionActions.setFilter(filter));
  },
  saveFleetConjunctionColumns: (columns) => {
    return dispatch(fleetConjunctionsLayoutActions.saveItems(columns));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetStatus);
