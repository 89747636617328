import createConjunctionsFetcher from "./conjunctions";
import KeycloakManager from "../keycloak";
import { parseSort } from "../helpers/sorting";
import { parseFilter } from "../helpers/filtering";
import { createUseDetail, createUseFetchWithConfig } from "../helpers/hooks";
import { createDetailFetcher } from "../helpers/fetchers";
import { createUseItems } from "../configuration/fetchHooks";
import { checkResponse } from "../helpers";
import createItemFetcher from "../helpers/item";
import { combineDucks } from "../helpers/combineDucks";

// CONJUNCTION DETAILS
const conjunctionDetailFetcher = () => createDetailFetcher("CONJUNCTION");
export const conjunctionDetail = conjunctionDetailFetcher();
export const useConjunctionDetail = createUseDetail(
  conjunctionDetail,
  (state) => state.conjunctionDetail
);

// CONJUNCTIONS
export const conjunctions = createConjunctionsFetcher({
  storeId: "CONJUNCTIONS",
  computeURL: () => "api/v1/conjunctions",
});

// FLEET CONJUNCTIONS
export const fleetConjunctions = createConjunctionsFetcher({
  storeId: "FLEET_CONJUNCTIONS",
  computeURL: () => "api/v1/conjunctions/fleetstatus",
});
export const useFleetConjunctions = createUseItems(
  fleetConjunctions,
  (state) => state.fleetConjunctions
);

const _getConjunctionsPage = async ({ size, page, filter, sort }) => {
  const headers = { Authorization: `Bearer ${KeycloakManager.getToken()}` };
  const parsedFilter = parseFilter(filter);
  const parsedSort = parseSort(sort);
  const url = `/api/v1/conjunctions?pageSize=${size}&page=${page}${parsedFilter}${parsedSort}`;
  return await fetch(url, { headers });
};

export const getConjunctionsPage = async ({ size, page, filter, sort }) => {
  return await (
    await _getConjunctionsPage({ size, page, filter, sort })
  ).json();
};

const _getConjunctionRows = async ({ filter }) => {
  const headers = { Authorization: `Bearer ${KeycloakManager.getToken()}` };
  let parsedFilter = parseFilter(filter);
  if (parsedFilter) {
    parsedFilter = parsedFilter.slice(1);
  }
  const url = `/api/v1/conjunctions/count?${parsedFilter}`;
  return await fetch(url, { headers });
};

export const getConjunctionRows = async ({ filter }) => {
  const conjunctionsPromise = (await _getConjunctionRows({ filter })).json();
  const response = await conjunctionsPromise;
  return checkResponse(response, "conjunctions page");
};

export type ConjIdConfig = string;

export interface CDMInfo {
  id: number;
  operator: string;
  filename: string;
  fileContent: string;
}

const conjCDMUrlBuilder = (id: ConjIdConfig) => {
  return `/api/v1/conjunctions/${id}/cdm`;
};

const conjunctionCDMId = ( id: ConjIdConfig) => {
  return `conjunction_${id}_cdm`;
};

export const conjCDMFetcher = createItemFetcher<ConjIdConfig, unknown>(
  conjCDMUrlBuilder,
  "CONJ_CDM",
  conjunctionCDMId
);

export const useConjunctionCDM = createUseFetchWithConfig<ConjIdConfig, CDMInfo>(
  conjCDMFetcher,
  (state) => state.conjunctionCDM.cdm
);

export const conjCDMDuck = combineDucks({
  cdm: conjCDMFetcher,
});

export interface OPMInfo {
  id: number;
  operator: string;
  filename: string;
  fileContent: string;
}

const conjOPMUrlBuilder = (id: ConjIdConfig) => {
  return `/api/v1/conjunctions/${id}/opm`;
};

const conjunctionOPMId = ( id: ConjIdConfig) => {
  return `conjunction_${id}_opm`;
};

export const conjOPMFetcher = createItemFetcher<ConjIdConfig, unknown>(
  conjOPMUrlBuilder,
  "CONJ_OPM",
  conjunctionOPMId
);

export const useConjunctionOPM = createUseFetchWithConfig<ConjIdConfig, OPMInfo>(
  conjOPMFetcher,
  (state) => state.conjunctionOPM.opm
);

export const conjOPMDuck = combineDucks({
  opm: conjOPMFetcher,
});
