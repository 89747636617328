import React, { useRef } from "react";
import { ColDef, GridOptions } from "ag-grid-community";
import { isEqual } from "lodash";
import { TABLE_ACTION } from "./configurations";
import { Table } from "./Table";

interface InfiniteTableProps {
  history: any;
  location: any;
  rowsGetter: (param: object) => Promise<object[]>;
  saveColumns: (param: object) => void;
  getNumberOfRows?: (param: object) => Promise<number>;
  filter: object;
  saveFilter: (param: object) => void;
  columnDefinitions: ColDef[];
  activeColumns: { colId: string; hide: boolean }[];
  source?: string;
  base: string;
  actions: TABLE_ACTION[];
  downloadData: (param: { id: string }) => void;
  initialPage?: number;
  rowClassRules?: any;
}

export const InfiniteTable: React.FC<InfiniteTableProps> = ({
  history,
  location,
  rowsGetter,
  saveColumns,
  getNumberOfRows,
  filter,
  saveFilter,
  columnDefinitions,
  activeColumns,
  source,
  base,
  actions,
  downloadData,
  initialPage = 1,
  rowClassRules,
}) => {
  // Number of rows requested to the server everytime the last available row is reached
  const CACHE_BLOCK_SIZE = 30;

  let totalRows = undefined;
  let filterChanged = false;

  const api = useRef<any>(null);

  /**
   * Returns the datasource object that handles the requests of more rows to the server.
   * Once data is loaded, the 'moveToGridPageCallback' is run
   */
  const getDatasource = (moveToGridPageCallback) => {
    return {
      // 'getRows' function is called to include new rows in the table when the last
      // available row is reached, but there are more available in the server
      getRows: (rowParams) => {
        // 'getServerRows' requests a new rows-set to the serverm and executes the given callback
        const getServerRows = (moveToGridPageCallback?: () => void) => {
          // 'pageNumber' is the number of the page (of size CACHE_BLOCK_SIZE) to be requested to the server
          const pageNumber = Math.floor(rowParams.startRow / CACHE_BLOCK_SIZE);

          // Request new rows to the server
          const response = rowsGetter({
            page: pageNumber,
            size: CACHE_BLOCK_SIZE,
            sort: rowParams.sortModel,
            filter: rowParams.filterModel,
          });

          response.then((dataRows) => {
            // We do not want this to be triggered if the table has already been destroyed (unmounted)
            if(!api?.current?.destroyCalled){
              rowParams.successCallback(dataRows, totalRows);

              // This callback moves the grid to a the page given in the URL
              if (moveToGridPageCallback) {
                moveToGridPageCallback();
              }
            }
          });
        };

        if (!totalRows || filterChanged) {
          // 'getServerRows' needs to be called after requesting 'totalRows' param to the server
          // !totalRows: Component being rendered for the first time ('totalRows' is still undefined)
          // totalRows === 0: May occur after calling 'getNumberOfRows' below, meaning there are no rows available in the DB.
          //    In this case, the component displays an empty table and 'getRows' is not called again (since the total number of pages has been set),
          //    so this code block is not reached again
          // filter != filterModel: the whole table needs to be reset and 'totalRows' may have changed since the requested data server-filtered
          const resp = getNumberOfRows({
            filter: rowParams.filterModel,
          });
          // The callback executed after getting rows from the server is either setting the first page in the browser URL (in case the filter changed) or
          // moving to the page specified in the URL
          const callback = filterChanged
            ? changeUrlPage(1)
            : moveToGridPageCallback;
          // Reset filterChanged to false
          filterChanged = false;
          resp.then((rows) => {
            totalRows = rows;
            saveFilter(rowParams.filterModel);
            getServerRows(callback);
          });
        } else {
          saveFilter(rowParams.filterModel);
          getServerRows();
        }
      },
    };
  };

  // Initial variable that stores the columnApi to interact with AgGrid
  const [columnApi, setColumnApi] = React.useState({
    setColumnVisible: (name: string, value: boolean) => {
      console.error(
        "API not available. Tried to set column [" +
          name +
          "] visibility to [" +
          value +
          "]."
      );
    },
  });

  const onGridReady = (params) => {
    setColumnApi(params.columnApi);
    api.current = params.api;
    params.api.setFilterModel(filter);

    // Apply the column settings retrieved from the store's state or from the focusoc DB
    params.columnApi.applyColumnState({
      applyOrder: true,
      state: Object.values(activeColumns),
    });

    // Ensure initialPage is an integer. If the 'goToPage' method is called with a string,
    // the page number is not correctly displayed in the browser
    if (typeof initialPage === "string") {
      initialPage = parseInt(initialPage);
    }

    const moveToGridPageCallback = () => {
      // Load initialPage only if there are rows enough to load that page
      // Otherwise, move to the first page (browser pagination starts in 1 instead of 0)
      if (totalRows > (initialPage - 1) * params.api.paginationGetPageSize()) {
        params.api.paginationGoToPage(initialPage - 1);
      } else {
        changeUrlPage(1);
      }
    };
    params.api.setDatasource(getDatasource(moveToGridPageCallback));
  };

  const onPaginationChanged = (params) => {
    if (params.newPage) {
      const currentPage = params.api.paginationGetCurrentPage();
      // The page number displayed in the browser starts in 1 instead of 0
      changeUrlPage(currentPage + 1);
    }
  };

  /**
   * changeUrlPage modifies the browser's URL to include the current page number
   */
  const changeUrlPage = (page: number) => {
    let originalURL = location.pathname;
    originalURL = originalURL.endsWith("/")
      ? originalURL.slice(0, originalURL.length - 1)
      : originalURL;

    let newURL = "";
    const urlItems = originalURL.split("/");
    const lastUrlItem = urlItems[urlItems.length - 1];

    if (lastUrlItem) {
      if (!isNaN(parseInt(lastUrlItem))) {
        // Remove the page number from the URL
        urlItems.pop();
      }
      urlItems.forEach((item) => {
        newURL = `${newURL}${item}/`;
      });
      // Include the page number in the URL
      newURL = `${newURL}${page}`;
    }

    if (newURL !== originalURL) {
      history.push(newURL);
    }
  };

  const onFilterChanged = (params) => {
    // set filterChanged to true only if the filter has changed after the initial grid load
    filterChanged = !isEqual(filter, params.api.getFilterModel())
      ? true
      : false;
  };

  const gridOptions: GridOptions = {
    onGridReady: onGridReady,
    onPaginationChanged,
    onFilterChanged,
    pagination: true,
    paginationAutoPageSize: true,
    rowModelType: "infinite",
    maxBlocksInCache: 10,
    cacheBlockSize: CACHE_BLOCK_SIZE,
    maxConcurrentDatasourceRequests: 3,
  };

  return (
    <Table
      saveColumns={saveColumns}
      columnDefinitions={columnDefinitions}
      gridOptions={gridOptions}
      context={{
        source: source || undefined,
        base: base,
        actions: actions,
        downloadData: downloadData,
      }}
      rowClassRules={rowClassRules}
      columnApi={columnApi}
      activeColumns={activeColumns}
    />
  );
};
