import { getElementDescendant } from "../../helpers/utils";

const getFilterType = (filterType) => {
  switch (filterType) {
    case "numerical":
      return "agNumberColumnFilter";
    case "date":
      return "agDateColumnFilter";
    case "text":
      return "agTextColumnFilter";
    default:
      return undefined;
  }
};

export const getValueFormatter = (inputFormat) => {
  if (inputFormat) {
    return (params) => {
      const value = getElementDescendant(
        params?.data,
        params?.colDef.field.split(".")
      );
      return readFormat(inputFormat)(value);
    };
  }
  return (params) => params.value?.toString();
};

export const readFormat = (inputFormat) => {
  if (inputFormat) {
    // Admitted formats are:
    // - decimal (e.g. 'd4' : rounds the given value to 4 decimals)
    // - exponential (e.g. 'e6' : converts the given value to exponential notation with 6 decimals)
    const admittedFormattings = /^([dD]|[eE])(\d+)$/g;
    const match = admittedFormattings.exec(inputFormat);

    if (match && match.length === 3) {
      const formatterType = match[1].toLowerCase();
      const formatterValue = match[2];
      switch (formatterType) {
        case "d":
          return (value) => {
            if (typeof value === "number") {
              return value.toFixed(parseInt(formatterValue));
            }
            return value;
          };
        case "e":
          return (value) => {
            if (typeof value === "number") {
              return value.toExponential(parseInt(formatterValue));
            }
            return value;
          };
      }
    }
  }
  return (params) => params.value?.toString();
};

export const getColumnDefinitions = (
  headers,
  includeActionsColumn: boolean
) => {
  if (!headers)
  {
    return [];
  }
  const colDefs = headers.map((header) => {
    const filter = getFilterType(header.filterType);
    const filterParams = {};
    if (filter) {
      filterParams["buttons"] = ["apply", "reset"];
      filterParams["closeOnApply"] = true;
      if (header.title === "Dangerous") {
        filterParams["filterOptions"] = ["equals"];
      }
    }

    let field = header.field;
    let headerName = header.title;
    if (header.units) {
      headerName = `${header.title} (${header.units})`;
    }

    const valueFormatter = getValueFormatter(header.format);

    return {
      headerName,
      field,
      filter,
      filterParams,
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      valueFormatter,
      hide: !header.initialView,
    };
  });

  if (includeActionsColumn) {
    colDefs.push(ACTIONS_COLDEF);
  }

  return colDefs;
};

export const actionsColumnName = "Actions";

export const getVisibleColumns = (
  serverDisplayConfig,
  defaultVisibleValues,
  completeColumnDefinitions
) => {
  // First, return the layout stored in the server, if any:
  if (serverDisplayConfig?.length > 0) {
    return serverDisplayConfig;
  }

  // In case there is no layout config saved in the server, get the default layout settings from the conjunction headers configuration (also retrieved from the server)
  const visibleColumns = completeColumnDefinitions.map((element) => ({
    colId: element.field,
    hide: element.field === actionsColumnName ? false : element.hide,
  }));

  // If there are no visible columns, but the Actions column, show the default layout
  const visibleColumnsButActionsCol = visibleColumns.filter(
    (vc) => !vc.hide && vc.colId !== actionsColumnName
  );
  if (visibleColumnsButActionsCol.length === 0) {
    return completeColumnDefinitions.map((e) => {
      const columnVisibility =
        defaultVisibleValues.indexOf(e.field) !== -1 ||
        e.field === actionsColumnName
          ? false
          : true;
      return { colId: e.field, hide: columnVisibility };
    });
  }
  return visibleColumns;
};

export const ACTIONS_COLDEF = {
  field: actionsColumnName,
  cellRenderer: "actionsColumn",
  cellClass: "lock-pinned",
  suppressMenu: true,
  sortable: false,
  lockPosition: true,
  minWidth: 90,
  maxWidth: 90,
};
