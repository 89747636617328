import { Checkbox } from "@blueprintjs/core";
import React, { useState } from "react";
import black_burguer_icon from "../../assets/images/black-burger.svg";
import { actionsColumnName } from "../../services/helpers/headers";

interface Props {
  columns: { headerName?: string; field?: string }[];
  checkBoxes: { [id: string]: boolean };
  toggleVisibility: (id: string) => void;
}

export const TableSideBar: React.FC<Props> = ({
  columns,
  checkBoxes,
  toggleVisibility,
}) => {
  const [open, setOpen] = useState(false);
  const visibilityClass = open
    ? "foc-aggrid-sidebar_container--open"
    : "foc-aggrid-sidebar_container--close";
  return (
    <>
      <div
        className="foc-aggrid-sidebar"
        onClick={() => setOpen(!open)}
      >
        <div className="foc-aggrid-sidebar-test-and-icon">
          <img
            className="foc-aggrid-sidebar_icon"
            src={black_burguer_icon}
            alt="chart"
          />
          <span className="foc-aggrid-sidebar_text">Columns configuration</span>
        </div>
      </div>
      <div
        className={`foc-aggrid-sidebar_container ${visibilityClass}`}
      >
        <div className="foc-aggrid-sidebar_container_form">
          <div className="foc-aggrid-sidebar_container_cb">
            {columns.map((column, index) => {
              const columnId = column.field
                ? column.field
                : column.headerName
                ? column.headerName
                : "" + index;
              if (columnId !== actionsColumnName) {
                return (
                  <Checkbox
                    key={columnId}
                    label={column.headerName}
                    checked={!checkBoxes[columnId]}
                    onChange={() => toggleVisibility(columnId)}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
  </>
  );
};
