import { StatusProxy } from "@focus/focusUI";
import React from "react";
import { useSavedPostCamConjunctionColumns } from "../../services/configuration";
import { CONJ_DEFAULT_VISIBLE_COLUMNS } from "../../services/conjunctions/Headers";
import {
  getColumnDefinitions,
  getVisibleColumns,
} from "../../services/helpers/headers";
import { useOpmConjunctions } from "../../services/postcam";
import { TABLE_ACTION } from "../table/configurations";
import { FiniteTable } from "../table/FiniteTable";
import "../../assets/stylesheets/internal_pages.css";
import "../../assets/stylesheets/tabs.css";

interface PostCAMConjunctionsTableProps {
  opmId: number;
  availableConjHeaders: any;
  savePostOpmConjunctionColumns?: () => void;
  setStatePostOpmConjunctionsFilter?: (param: { filter: object }) => void;
  getStatePostOpmConjunctionsFilter?: () => { filter: object };
}

export const PostCAMConjunctionsTable: React.FC<
  PostCAMConjunctionsTableProps
> = ({
  opmId,
  availableConjHeaders,
  savePostOpmConjunctionColumns,
  setStatePostOpmConjunctionsFilter,
  getStatePostOpmConjunctionsFilter,
}) => {
  const conjunctions = useOpmConjunctions({ id: opmId });
  const savedColsLayout = useSavedPostCamConjunctionColumns();
  const columnDefinitions = getColumnDefinitions(availableConjHeaders, false);

  // If no headers are available in the DB, load the default active columns
  const visibleColumns = getVisibleColumns(
    savedColsLayout.items,
    CONJ_DEFAULT_VISIBLE_COLUMNS,
    columnDefinitions
  );

  return (
    <div className="w-100 vertical-flex">
      <StatusProxy
        status={savedColsLayout.status}
        error={savedColsLayout.error}
      >
        <StatusProxy status={conjunctions.status} error={conjunctions.error}>
          <div className="element-conjunctions">
            <div className="foc-page_subtitle">
              <span>Post-CAM Conjunctions</span>
            </div>
            <FiniteTable
              rowData={conjunctions.items}
              saveColumns={savePostOpmConjunctionColumns}
              columnDefinitions={columnDefinitions}
              setFilter={setStatePostOpmConjunctionsFilter}
              getFilter={getStatePostOpmConjunctionsFilter}
              activeColumns={visibleColumns}
              base={"conjunctions"}
              actions={[TABLE_ACTION.INFO]}
              downloadData={undefined}
              rowClassRules={{
                "row-alert": (params) =>
                  params.api.getValue("riskLevel.value", params.node) ===
                  "ALERT",
                "row-warning": (params) =>
                  params.api.getValue("riskLevel.value", params.node) ===
                  "WARNING",
              }}
              adaptToContainerHeight={false}
            />
          </div>
        </StatusProxy>
      </StatusProxy>
    </div>
  );
};
